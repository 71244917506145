import '../../style/animation.css';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { t } from 'i18next';
import { Button } from 'antd';
import CommButton from '../common/Button';
import useRobotstore from '../../store/robotStore';
import useCommonstore from '../../store/modalStore';
import useModalstore from '../../store/modalStore';
import {
  CurrentTask,
  DeviceDataType,
  MultiRobotCommandType,
  ScheduleSocketData,
  TaskRobotType,
} from '../../_types';
import DetailError from '../error/DetailError';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getCommandList, postSiteCommand } from '../../_api/rest/robot';
import { stopWorkflowApi } from '../../_api/rest/workflow';
import { deleteTask, postStopTask } from '../../_api/rest/schedule';
import TaskCard from '../schedule/common/TaskCard';
import { SECOND_SOCKET_URL } from '../../_api/webSocket';
import { io } from 'socket.io-client';

type PropsType = {
  robotSocketData: DeviceDataType[] | null;
};
export default function MultiRobotDetail({ robotSocketData }: PropsType) {
  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const selectedSiteId = useRef<string>('');

  const { openModal } = useCommonstore();

  const {
    isShowMultiRobotDetail,
    selectedTaskDetailData,
    selectedTask,
    updateIsShowMultiRobotDetail,
    changeIsLoading,
  } = useRobotstore();
  const { closeModal } = useModalstore();

  const [multiRobotData, setMultiRobotData] = useState<
    DeviceDataType[] | null
  >();
  const [commandList, setCommandList] = useState<MultiRobotCommandType[]>([]);
  const [siteSocket, setSiteSocket] = useState<ScheduleSocketData[]>([]);
  const [currentTaskData, setCurrentTaskData] = useState<CurrentTask | null>(
    null,
  );

  useEffect(() => {
    if (!selectedTask) return;

    setMultiRobotData(
      robotSocketData?.filter(({ deviceId }) =>
        selectedTaskDetailData?.robotList
          ?.map((robot: TaskRobotType) => robot.deviceId)
          .includes(deviceId),
      ),
    );
  }, [robotSocketData, selectedTask]);

  useEffect(() => {
    (async () => {
      if (!selectedTaskDetailData?.siteId) return;
      if (selectedSiteId.current === selectedTaskDetailData?.siteId) return;

      const commandList = await getCommandList(selectedTaskDetailData?.siteId);
      setCommandList(commandList);
      selectedSiteId.current = selectedTaskDetailData?.siteId;
    })();
  }, [selectedTaskDetailData]);

  useEffect(() => {
    const siteSocket = io(`${SECOND_SOCKET_URL}/sites`, {
      transports: ['websocket'],
      upgrade: true,
    });

    if (isShowMultiRobotDetail) {
      siteSocket.on('connect', () => {
        console.log('site socket connected');
      });

      siteSocket.on('sites', (data: ScheduleSocketData[]) => {
        setSiteSocket(data);
      });
    } else {
      siteSocket.disconnect();
      siteSocket.off('connect');
      siteSocket.off('sites');
    }

    siteSocket.on('connect_error', (err) => {
      console.error('socket connection error : ', err);
      toast.error('데이터 연결에 문제가 발생했습니다.');
    });
  }, [isShowMultiRobotDetail]);

  useEffect(() => {
    if (!siteSocket || !selectedTaskDetailData) return;

    setCurrentTaskData(
      siteSocket
        .find((site) => site.siteId === selectedTaskDetailData.siteId)
        ?.currentTask.find(
          (task) => task.taskId === selectedTaskDetailData.currentTask.taskId,
        ) || null,
    );
  }, [siteSocket, selectedTaskDetailData]);

  const matchStateColor = (robotState: string) => {
    switch (robotState) {
      case 'RED':
        return 'bg-red-500';
      case 'GREEN':
        return 'bg-emerald-500';
      case 'ORANGE':
        return 'bg-orange-400';
      case 'GRAY':
        return 'bg-gray-400';
      default:
        return 'bg-gray-400';
    }
  };

  const closeMenu = () => {
    updateIsShowMultiRobotDetail(false);
  };

  const handleExecuteTask = async (command: string) => {
    changeIsLoading(true);

    const params = {
      siteId: selectedTaskDetailData?.siteId,
      taskId: selectedTaskDetailData?.taskId,
      command,
    };

    const res = await postSiteCommand(params);
    res?.result === 'SUCCESS'
      ? toast.success('태스크를 실행했습니다.') && changeIsLoading(false)
      : toast.error('태스크 실행에 실패했습니다.') && changeIsLoading(false);
  };

  const handleStopTask = async () => {
    changeIsLoading(true);

    const params = {
      siteId: selectedTaskDetailData?.siteId,
      taskId: selectedTaskDetailData?.currentTask?.taskId,
      activation: 'STOP',
    };

    const res = await stopWorkflowApi(params);
    res?.result === 'SUCCESS'
      ? toast.success('태스크를 중지했습니다.') && changeIsLoading(false)
      : toast.error('태스크 중지에 실패했습니다.') && changeIsLoading(false);
  };

  const style = !selectedTask
    ? 'w-[0px]'
    : `z-[100] fixed right-0 w-[650px] h-[100vh] p-6 px-7 bg-white flex flex-col drop-shadow overflow-scroll ${
        isShowMultiRobotDetail ? 'robot-menu-on' : 'robot-menu-off'
      }`;

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    command: string,
  ) => {
    event.preventDefault();

    changeIsLoading(true);

    const userName = userNameRef.current?.value;
    const password = passwordRef.current?.value;

    if (!userName || !password) {
      return toast.error('이름과 비밀번호를 입력해주세요.');
    }

    const params = {
      siteId: selectedTaskDetailData?.siteId,
      taskId: selectedTaskDetailData?.taskId,
      command,
      param: {
        userName,
        password,
      },
    };

    const res = await postSiteCommand(params);

    if (res.result === 'SUCCESS') {
      toast.success('정보를 입력했습니다.');
      closeModal();
      changeIsLoading(false);
    } else {
      toast.error('정보 입력에 실패했습니다.');
      changeIsLoading(false);
    }
  };

  const handleInputInfo = (command: string) => {
    openModal({
      type: 'info',
      title: '정보 입력',
      contents: (
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(event, command)
          }
          className="flex flex-col items-center justify-center w-full py-5 mt-3 space-y-4 text-sm px-14"
        >
          <div className="flex flex-row items-center w-full">
            <label
              htmlFor="patientName"
              className="flex-shrink-0 w-1/4 text-left text-gray-700"
            >
              환자명
            </label>
            <input
              type="text"
              id="patientName"
              ref={userNameRef}
              autoComplete="off"
              className="flex-grow p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-row items-center w-full">
            <label
              htmlFor="password"
              className="flex-shrink-0 w-1/4 text-left text-gray-700"
            >
              비밀번호
            </label>
            <input
              type="password"
              id="password"
              ref={passwordRef}
              autoComplete="off"
              onInput={({ currentTarget }) => {
                const value = currentTarget.value.replace(/\D/g, '');
                currentTarget.value = value.slice(0, 4);
              }}
              className="flex-grow p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex px-12 py-2 justify-center gap-[30px] w-full min-w-[300px]">
            <CommButton
              label={`${t('confirm')}`}
              circle
              full
              type="submit"
              size="small"
              primary
              onClick={(event: React.FormEvent<HTMLFormElement>) =>
                handleSubmit(event, command)
              }
            />
            <CommButton
              label={`${t('cancel')}`}
              full
              circle
              type="button"
              size="small"
              onClick={closeModal}
            />
          </div>
        </form>
      ),
    });
  };

  return (
    <div className={style} data-name="detail">
      {selectedTaskDetailData ? (
        <ErrorBoundary FallbackComponent={DetailError}>
          <div
            onClick={closeMenu}
            className="self-end text-black hover:cursor-pointer"
          >
            ✕
          </div>
          <div className="pb-3">
            <span className="text-2xl">{selectedTaskDetailData.taskName}</span>
            <span className="text-xl">
              : {selectedTaskDetailData.description}
            </span>
          </div>

          {currentTaskData && (
            <TaskCard
              taskId={currentTaskData.taskId}
              taskName={currentTaskData.taskName}
              taskState={currentTaskData.taskState}
              currentAction={currentTaskData.currentAction}
              prevAction={currentTaskData.prevAction}
              agentNameList={currentTaskData.agentNameList}
              currentActionAlias={currentTaskData.currentActionAlias}
              prevActionAlias={currentTaskData.prevActionAlias}
              currentActionCount={currentTaskData.currentActionCount}
              totalActionCount={currentTaskData.totalActionCount}
              startTime={currentTaskData.startTime}
              lastUpdateTime={currentTaskData.lastUpdateTime}
              ellapsedTimeTotal={currentTaskData.ellapsedTimeTotal}
              isStopped={currentTaskData.taskState === 'USER_STOP'}
              onDeleteClick={() => {
                deleteTask({
                  siteId: selectedTaskDetailData.siteId,
                  taskId: currentTaskData.taskId,
                })
                  .then(() => {
                    toast.success(t('It_has_been_deleted'));
                    closeModal();
                  })
                  .catch((error) => toast.error(`${error}`));
              }}
            />
          )}

          <div className="mt-6 mb-7">
            <div className="mb-2 text-sm font-bold">{t('Put_Robot_Info')}</div>
            <div className="w-full text-xs text-center">
              <div className="flex items-center font-bold rounded-lg shadow-sm bg-neutral-200">
                <div className="p-2 w-[15%]">{t('Device_Name')}</div>
                <div className="p-2 w-[23%]">{t('Model_Name')}</div>
                <div className="p-2 w-[22%]">{t('Current_Status')}</div>
                <div className="p-2 w-[7%]">{t('status')}</div>
                <div className="p-2 w-[13%]">{t('battery_level')}</div>
                <div className="p-2 w-[20%]">{t('Data_Update_Time')}</div>
              </div>

              <div>
                {multiRobotData?.map((item: DeviceDataType, idx: number) => (
                  <div
                    key={idx}
                    data-name="list"
                    className={`flex items-center bg-white hover:cursor-pointer hover:bg-[#d3ece8] border border-gray-300 ${
                      localStorage.login === 'true' &&
                      localStorage.verified === 'true'
                        ? 'justify-center mt-1 rounded-xl shadow-sm'
                        : 'blur-sm'
                    }`}
                  >
                    <div className="p-2 w-[15%]">{item.name ?? '-'}</div>
                    <div className="p-2 w-[23%]">{item.modelName ?? '-'}</div>
                    <div className="p-2 w-[22%]">{item.robotState ?? '-'}</div>
                    <div className="p-2 w-[7%]">
                      <span
                        className={`inline-block w-[9px] h-[9px] rounded-full ${matchStateColor(item.robotStateColor)}`}
                      ></span>
                    </div>
                    <div className="p-2 w-[13%]">
                      {item.batteryLevel ? `${item.batteryLevel}%` : '-'}
                    </div>
                    <div className="p-2 w-[20%]">
                      {item.lastUpdateTime
                        ? dayjs(item.lastUpdateTime).format('MM-DD HH:mm:ss')
                        : '-'}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            {commandList.map((command: MultiRobotCommandType) => {
              switch (command.name) {
                case 'COMMAND_ADD_INFO':
                  return (
                    <Button
                      key={command.name}
                      className={'buttonSwitchPrimary w-[110px]'}
                      onClick={() => handleInputInfo(command.name)}
                      type="primary"
                      disabled={false}
                    >
                      {t('Input_Info')}
                    </Button>
                  );
                case 'COMMAND_TASK':
                  return (
                    <Fragment key={command.name}>
                      {[
                        'TASK_POSSIBLE',
                        'TASK_IMPOSSIBLE',
                        'TASK_COMPLETE',
                      ].includes(selectedTaskDetailData.taskMainState) ? (
                        <Button
                          className={'buttonSwitchPrimary w-[110px]'}
                          onClick={() => handleExecuteTask(command.name)}
                          type="primary"
                          disabled={[
                            'TASK_IMPOSSIBLE',
                            'TASK_COMPLETE',
                          ].includes(selectedTaskDetailData.taskMainState)}
                        >
                          {t('Execute_Task')}
                        </Button>
                      ) : (
                        <Button
                          className={'buttonSwitchPrimary w-[110px]'}
                          onClick={handleStopTask}
                          type="primary"
                          disabled={
                            selectedTaskDetailData.currentTask?.taskState ===
                            'USER_STOP'
                          }
                        >
                          {t('Stop_Task')}
                        </Button>
                      )}
                    </Fragment>
                  );
                default:
                  return;
              }
            })}
          </div>
        </ErrorBoundary>
      ) : null}
    </div>
  );
}
