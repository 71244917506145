export const TASK_ERROR_TYPES = ['NOTICE', 'ERROR'] as const;

export interface completeTaskParams {
  siteIds: string;
  startDate: string;
  endDate: string;
  robotModelTypeEn?: string;
  alarmType?: string;
}

export interface errorCodeListType {
  name: string;
  statement: string;
}

export interface selectOptionType {
  value: string;
  label: string;
}

export interface completeTaskType {
  alarmType: (typeof TASK_ERROR_TYPES)[number];
  completeStatus: '정상' | '비정상';
  deviceIds: string[];
  deviceTypes: string;
  ellapsedTimeTotal: string;
  errorStatement: string | null;
  errorCode?: errorCodeListType;
  id: number;
  lastUpdateTime: Date | string;
  robotModelTypeEnList: string[];
  robotModelTypeList: string[];
  robotNameList: string[];
  siteId: string;
  startTime: Date | string;
  taskId: string;
  taskName: string;
  taskState: string;
  taskStateAlias: string;
  taskStateType: string;
  time: string;
}

export interface completeTaskExcelType {
  시작시간: string;
  종료시간: string;
  소요시간: string;
  작업명: string;
  로봇타입: string;
  로봇명: string;
  알람구분: (typeof TASK_ERROR_TYPES)[number];
  세부내용: string;
  '에러 세부내용': completeTaskType['errorStatement'];
  완료여부: completeTaskType['completeStatus'];
}
