import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

//import reportWebVitals from "./default/reportWebVitals";
import { ConfigProvider } from 'antd';
import './util/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './pages/ErrorPage';
import Spinner from './components/loading/Spinner';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient({});
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <React.Suspense
          fallback={
            <Spinner
              // size="large"
              personalStyle=" flex items-center justify-center"
            />
          }
        >
          <ConfigProvider theme={{ token: { colorPrimary: '#08B295' } }}>
            <App />
          </ConfigProvider>
          {/* <Spinner /> */}
        </React.Suspense>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>,
  // </React.StrictMode>
);
