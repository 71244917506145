import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ko from '../locales/ko/ko.json';
import en from '../locales/en/en.json';

const resources = {
  en: {
    translation: en,
  },
  ko: {
    translation: ko,
  },
};

i18next.use(initReactI18next).use(LanguageDetector).init({
  // debug: true,
  debug: false,
  fallbackLng: 'ko',
  resources,
});
