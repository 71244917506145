import axios from 'axios';
import core from '../../config/axios';
import { UserInfo } from 'firebase/auth';

const { plusClient } = core;

export async function changePassword(uid: string, password: string) {
  return axios.post(
    'https://asia-northeast3-sollink-f2425.cloudfunctions.net/api/auth/accounts/change-password',
    //"http://127.0.0.1:5001/sollink-f2425/asia-northeast3/api/auth/accounts/change-password",
    {
      uid,
      password,
    },
  );
}

/**
 * 솔링크 신규가입시 SOLlink Plus로 firebase uid 전달하는 함수
 */
export const postFirebaseUidToPlus = async (data: {
  userUuid: UserInfo['uid'];
  userName: UserInfo['displayName'];
}) => {
  try {
    const res = await plusClient.post(`/api/user`, data);

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error();
  }
};
