import TaskCard from '../../schedule/common/TaskCard';
import { TitleWidget } from '../../dashboard/common/label/titleWidget';
import { t } from 'i18next';
import { RiExternalLinkFill } from 'react-icons/ri';
import useTaskData from '../../../hooks/useTaskData';

const ProgressBar = () => {
  const { taskSiteSocketData } = useTaskData({ type: 'sites' });

  return (
    <div className={'p-4 flex flex-col h-full'}>
      <div className={'flex border-b-[1px] border-gray-100 '}>
        <div className={'content-center'}>
          <TitleWidget
            fontSize={14}
            title={t('Ongoing_Task')}
            padding={'0px 10px 10px 10px'}
            useLink={false}
          />
        </div>

        <div className={'content-center ml-auto'}>
          <TitleWidget
            fontSize={13}
            title={t('more')}
            icon={RiExternalLinkFill}
            iconPosition={'left'}
            iconSize={16}
            iconMarginL={3}
            padding={'0px 10px 10px 10px'}
            useLink={true}
            linkAddress={'/history'}
          />
        </div>
      </div>
      {taskSiteSocketData.length > 0 ? (
        <div className={'mt-4 w-full flex-grow'}>
          <TaskCard
            taskId={taskSiteSocketData[0].currentTask[0].taskId}
            taskName={taskSiteSocketData[0].currentTask[0].taskName}
            taskState={taskSiteSocketData[0].currentTask[0].taskState}
            currentAction={taskSiteSocketData[0].currentTask[0].currentAction}
            prevAction={taskSiteSocketData[0].currentTask[0].prevAction}
            agentNameList={taskSiteSocketData[0].currentTask[0].agentNameList}
            currentActionAlias={
              taskSiteSocketData[0].currentTask[0].currentActionAlias
            }
            prevActionAlias={
              taskSiteSocketData[0].currentTask[0].prevActionAlias
            }
            currentActionCount={
              taskSiteSocketData[0].currentTask[0].currentActionCount
            }
            totalActionCount={
              taskSiteSocketData[0].currentTask[0].totalActionCount
            }
            startTime={taskSiteSocketData[0].currentTask[0].startTime}
            lastUpdateTime={taskSiteSocketData[0].currentTask[0].lastUpdateTime}
            ellapsedTimeTotal={
              taskSiteSocketData[0].currentTask[0].ellapsedTimeTotal
            }
            isStopped={
              taskSiteSocketData[0].currentTask[0].taskState === 'USER_STOP'
            }
          />
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <p className="opacity-25 my-[100px]">
            {t('There_is_no_task_currently_in_progress')}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
