import core from '../../config/axios';
import { Floor, StandardMap } from '../../_types/map';

const { client } = core;

export const getFloorBySiteId = async (siteId: string): Promise<Floor[]> => {
  try {
    const res = await client.get(`site-floor/by-site?siteId=${siteId}`);

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('층을 가져오는데 실패했습니다.');
  }
};

export const getStandardMap = async (
  siteId: string,
  floorIndex: string,
): Promise<StandardMap> => {
  try {
    const res = await client.get(
      `standard-map/by-site-and-floor?siteId=${siteId}&floorIndex=${floorIndex}`,
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('표준지도를 가져오는데 실패했습니다.');
  }
};
