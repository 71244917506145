import { useEffect, useState } from 'react';
import '../../../style/animation.css';

import { CommonCommandType, DeviceDataType } from '../../../_types';
import JobOrderButton from './JobOrderButton';
import dayjs from 'dayjs';
import useRobotstore from '../../../store/robotStore';

import SplashBGPng from '../../../assets/image/image_map_splash_bg.png';
import SplashArrowPng from '../../../assets/image/image_map_splash_arrow.png';
import SplashTitleSvg from '../../../assets/image/image_map_splash_title.svg';
import { OperationVideo } from '../video/OperationVideo';
import { t } from 'i18next';

import MapCard from '../../map/robot/MapCard';

import useTaskData from '../../../hooks/useTaskData';
import TaskCard from '../../schedule/common/TaskCard';

import { getRobotStateColor } from '../../../util/styleString';

import { deleteTask, syncRedisData } from '../../../_api/rest/schedule';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import { TbRefresh } from 'react-icons/tb';

type PropsType = {
  socketData: DeviceDataType[];
};

export default function CommonDetail({ socketData }: PropsType) {
  const { selectedRobot, updateIsShowRobotDetail, isShowRobotDetail } =
    useRobotstore();

  const { taskDeviceSocketData } = useTaskData({ type: 'devices' });

  // 디테일 페이지에 표시되는 로봇 정보 담는 객체, 웹소켓 데이터 타입 지정 필요
  const [robotDetailData, setRobotDetailData] = useState<any>(null);

  // 선택된 로봇의 디바이스 아이디 저장
  const [deviceId, setDeviceId] = useState<string>();
  const [cmdList, setCmdList] = useState<CommonCommandType[]>([]);

  const [showSplash, setShowSplash] = useState(true);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const handleClickRefresh = async () => {
    setRefreshing(true);

    try {
      await syncRedisData();
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setTimeout(() => {
        setRefreshing(false);
      }, 400);
    }
  };

  useEffect(() => {
    if (socketData.length === 0 || !selectedRobot) return;

    const robotDetailData = socketData.find(
      (item: DeviceDataType) => item.deviceId === selectedRobot,
    );
    setRobotDetailData(robotDetailData);
  }, [socketData, selectedRobot]);

  useEffect(() => {
    if (robotDetailData) {
      setDeviceId(robotDetailData.deviceId);
      setCmdList(robotDetailData.commandList);
    }
  }, [robotDetailData]);

  useEffect(() => {
    setShowSplash(true);
  }, [deviceId]);

  const closeMenu = () => {
    updateIsShowRobotDetail(false);
    setDeviceId('');

    setRobotDetailData(null);
    setShowSplash(true);
  };
  return (
    <>
      {robotDetailData && (
        <>
          <div
            onClick={closeMenu}
            className="self-end text-black hover:cursor-pointer"
          >
            ✕
          </div>
          <div className="text-2xl">{t('Detailed_Information')}</div>
          <div className="mt-6 mb-7">
            <div className="mb-2 text-sm font-bold">
              {t('Basic_Information')}
            </div>
            {robotDetailData.modelType && (
              <div className=" text-center text-xs w-[98%]">
                <div className="flex items-center font-bold rounded-lg shadow-sm bg-neutral-200">
                  <div className="w-[120px] p-2">{t('Type')}</div>
                  <div className="w-[120px] p-2">{t('robot_name')}</div>
                  <div className="w-[120px] p-2">{t('Manufacturer')}</div>
                  <div className="w-[150px] p-2">{t('Model_Name')}</div>
                  <div className="w-[180px] p-2">{t('Waiting_Area')}</div>
                  <div className="w-[180px] p-2">{t('Charging_Station')}</div>
                </div>
                <div>
                  <div
                    key={robotDetailData.id}
                    className="flex items-center mt-2 bg-white rounded-lg shadow-sm"
                  >
                    <div className="w-[120px] p-2">
                      {robotDetailData.modelType ?? '-'}
                    </div>
                    <div className="w-[120px] p-2">
                      {robotDetailData.name ?? '-'}
                    </div>
                    <div className="w-[120px] p-2">
                      {robotDetailData.manufacturer ?? '-'}
                    </div>
                    <div className="w-[150px] p-2">
                      {robotDetailData.modelName ?? '-'}
                    </div>
                    <div className=" w-[180px] p-2">
                      {robotDetailData.modelType !== '방역로봇' &&
                        robotDetailData.modelType !== '청소로봇' && (
                          <span>{robotDetailData.homePoi ?? '-'}</span>
                        )}
                    </div>
                    {robotDetailData.modelType !== '방역로봇' && (
                      <div className="w-[180px] p-2">
                        {robotDetailData.chargePoi ?? '-'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {taskDeviceSocketData.length > 0 ? (
            <div className="mt-3 mb-7">
              <div className="mb-2 text-sm font-bold">{t('Status_Info')}</div>
              <TaskCard
                taskId={taskDeviceSocketData[0].taskId}
                taskName={taskDeviceSocketData[0].taskName}
                taskState={taskDeviceSocketData[0].taskState}
                currentAction={taskDeviceSocketData[0].currentAction}
                prevAction={taskDeviceSocketData[0].prevAction}
                agentNameList={taskDeviceSocketData[0].agentNameList}
                currentActionAlias={taskDeviceSocketData[0].currentActionAlias}
                prevActionAlias={taskDeviceSocketData[0].prevActionAlias}
                currentActionCount={taskDeviceSocketData[0].currentActionCount}
                totalActionCount={taskDeviceSocketData[0].totalActionCount}
                startTime={taskDeviceSocketData[0].startTime}
                lastUpdateTime={taskDeviceSocketData[0].lastUpdateTime}
                ellapsedTimeTotal={taskDeviceSocketData[0].ellapsedTimeTotal}
                isStopped={taskDeviceSocketData[0].taskState === 'USER_STOP'}
                onDeleteClick={() => {
                  deleteTask({
                    siteId: robotDetailData.siteId,
                    taskId: taskDeviceSocketData[0].taskId,
                  })
                    .then(() => {
                      toast.success(t('It_has_been_deleted'));
                    })
                    .catch((error) => toast.error(`${error}`));
                }}
              />
            </div>
          ) : null}

          <div className="mt-3 mb-7">
            <div className="mb-2 text-sm font-bold">{t('status')}</div>
            <div className="text-center text-xs w-[98%]">
              <div className="flex items-center font-bold rounded-lg shadow-sm bg-neutral-200">
                <div className="w-[50px] p-2">{t('status')}</div>
                {robotDetailData.modelType !== '홈케어로봇' && (
                  <div className="w-[120px] p-2">{t('Current_Task')}</div>
                )}
                {robotDetailData.modelType !== '홈케어로봇' &&
                  robotDetailData.modelType !== '방역로봇' && (
                    <div className="w-[120px] p-2">
                      {`${t('estimated_work_time')} ${t('second')}`}
                    </div>
                  )}
                {robotDetailData.modelType !== '홈케어로봇' && (
                  <div className="w-[100px] p-2">{t('Building_Name')}</div>
                )}
                <div className="w-[120px] p-2">{t('battery_level')} (%)</div>
                {robotDetailData.manufacturer === 'Gausium' && (
                  <div className="w-[120px] p-2">{t('clean_water')} (%)</div>
                )}
                {robotDetailData.manufacturer === 'Gausium' && (
                  <div className="w-[120px] p-2">{t('recovery_water')} (%)</div>
                )}

                <div className="w-[120px] p-2">{t('Data_Update_Time')}</div>
              </div>
              <div className="flex items-center mt-2 bg-white rounded-lg shadow-sm">
                <div className="w-[50px] p-2">
                  <span
                    className={`${getRobotStateColor(robotDetailData.robotStateColor)}`}
                  >
                    ●
                  </span>
                </div>
                {robotDetailData.modelType !== '홈케어로봇' && (
                  <div className="w-[120px] p-2">
                    {robotDetailData.robotStateString !== ''
                      ? robotDetailData.robotStateString
                      : '-'}
                  </div>
                )}
                {robotDetailData.modelType !== '홈케어로봇' &&
                  robotDetailData.modelType !== '방역로봇' && (
                    <div className="w-[120px] p-2">
                      {robotDetailData.standardLocation &&
                      robotDetailData.standardLocation.remainExpectedTime
                        ? Math.floor(
                            Number(
                              robotDetailData.standardLocation
                                .remainExpectedTime,
                            ),
                          )
                        : '-'}
                    </div>
                  )}
                {/* <td>{robotState}</td> */}
                {robotDetailData.modelType !== '홈케어로봇' && (
                  <div className="p-2 w-[100px]">
                    {robotDetailData.standardLocation?.buildingIndexAlias !== ''
                      ? robotDetailData.standardLocation?.buildingIndexAlias
                      : '-'}
                  </div>
                )}
                <div className="w-[120px] p-2">
                  {robotDetailData.batteryLevel !== ''
                    ? robotDetailData.batteryLevel
                    : '-'}
                </div>
                {robotDetailData.manufacturer === 'Gausium' && (
                  <div className="w-[120px] p-2">
                    {robotDetailData.cleaningRobotData?.cleanWaterTank ?? '-'}
                  </div>
                )}
                {robotDetailData.manufacturer === 'Gausium' && (
                  <div className="w-[120px] p-2">
                    {robotDetailData.cleaningRobotData?.recoveryWaterTank ??
                      '-'}
                  </div>
                )}

                <div className="w-[120px] p-2">
                  {robotDetailData.lastUpdateTime
                    ? dayjs(robotDetailData.lastUpdateTime).format(
                        'MM-DD HH:mm:ss',
                      )
                    : '-'}
                </div>
              </div>
            </div>
          </div>
          {cmdList && cmdList.length > 0 && (
            <div className="mt-3 mb-7">
              <div className="flex content-center">
                <div className="pb-2 text-sm font-bold pt-[2px]">
                  {t('Work_Order')}
                </div>
                <Button
                  className="ml-[10px]"
                  size="small"
                  onClick={handleClickRefresh}
                  type="primary"
                  ghost
                >
                  <TbRefresh
                    size="18"
                    color={'#08B295'}
                    className={refreshing ? 'animate-spin-fast' : ''}
                  />
                </Button>
              </div>

              <JobOrderButton
                deviceId={robotDetailData.deviceId}
                isOnline={
                  robotDetailData.isOnline ||
                  !(robotDetailData.robotStateColor === 'GRAY')
                }
                cmdList={cmdList.sort((a, b) => a.order - b.order)}
                detailData={robotDetailData}
                taskSocketData={taskDeviceSocketData}
              />
            </div>
          )}
          <div className="pt-3 pb-7">
            <div className="robot-route-container">
              <div className="pb-2 text-sm font-bold">
                {t('Robots_Movement_Path')}
              </div>
              <div>
                {
                  <div>
                    {showSplash ? (
                      <div className="map-splash-container">
                        <div className="map-splash-inner">
                          <div>
                            <img
                              className="map-splash-bg"
                              src={SplashBGPng}
                              alt="map splash bg"
                            ></img>
                            <img
                              className="map-splash-arrow"
                              src={SplashArrowPng}
                              onAnimationEnd={() => {
                                setShowSplash(false);
                              }}
                              alt="map splash arrow"
                            />
                          </div>
                          <img
                            className="map-splash-title"
                            src={SplashTitleSvg}
                            alt="map splash title"
                          />
                        </div>
                      </div>
                    ) : (
                      <MapCard
                        robotInfo={[
                          {
                            coordinate: {
                              x:
                                Math.abs(
                                  robotDetailData.standardLocation.mapX,
                                ) ||
                                Math.abs(robotDetailData.standardLocation.x),
                              y:
                                Math.abs(
                                  robotDetailData.standardLocation.mapY,
                                ) ||
                                Math.abs(robotDetailData.standardLocation.y),
                            },
                            robotStateColor: robotDetailData.robotStateColor,
                            shadow: false,
                          },
                        ]}
                        imageSrc={robotDetailData?.publicMapUrl || ''}
                        hasControls
                      />
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
          {robotDetailData.modelType &&
            (robotDetailData.modelType === '안내로봇' ||
              robotDetailData.modelType === '홈케어로봇') &&
            (robotDetailData.modelName === '포미' ||
              robotDetailData.modelName === '다솜이') && (
              <div className="pt-3 pb-7">
                <div className="robot-route-container">
                  <div className="pb-2 text-sm font-bold">
                    {t('Video_Call')}
                  </div>
                  <div>
                    {selectedRobot && (
                      <OperationVideo
                        type={
                          robotDetailData.modelName === '포미'
                            ? 'MINI'
                            : 'DASOM'
                        }
                        deviceId={robotDetailData.deviceId}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
        </>
      )}
    </>
  );
}
