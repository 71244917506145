import { useEffect, useState } from 'react';
import { Grid } from '../../dashboard/common/grid/grid';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TitleWidget } from '../../dashboard/common/label/titleWidget';
import { DateLabel } from '../../dashboard/common/label/dateLabel';

import { AiFillSound } from 'react-icons/ai';
import { RiExternalLinkFill } from 'react-icons/ri';
import { t } from 'i18next';

import useRobotData from '../../../hooks/useRobotData';
import useUserStore from '../../../store/userStore';

const ActionRequiredRobotsGrid = () => {
  interface ActionRequiredRobotsType {
    robotStateColor: string | null;
    name: string | null;
    robotStateString: string | null;
  }

  const { robotSocketData } = useRobotData();
  const { selectedUserAuthSite } = useUserStore();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowData, setRowData] = useState<ActionRequiredRobotsType[]>([]);
  const [columnDefs] = useState<ColDef<ActionRequiredRobotsType>[]>([
    {
      headerName: t('status'),
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        const { robotStateColor } = params.data;
        return (
          <div
            className={`flex items-center justify-center h-full text-l ${robotStateColor === 'RED' ? 'text-red-500' : 'text-gray-400'}`}
          >
            ●
          </div>
        );
      },
    },
    {
      headerName: t('robot_name'),
      field: 'name',
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 2,
    },
    {
      headerName: t('Current_Task'),
      field: 'robotStateString',
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 3,
    },
  ]);

  useEffect(() => {
    const data = robotSocketData
      .filter(
        ({ robotStateColor }) =>
          robotStateColor === 'RED' || robotStateColor === 'GRAY',
      )
      .slice(0, 3)
      .map(({ robotStateColor, name, robotStateString }) => {
        return {
          robotStateColor,
          name,
          robotStateString,
        };
      });

    setRowData(data);
    setIsLoading(false);
  }, [robotSocketData]);

  useEffect(() => setIsLoading(true), [selectedUserAuthSite]);

  return (
    <div className={'p-4 flex flex-col h-full'}>
      <div className={'flex border-b-[1px] border-gray-100 '}>
        <div className={'content-center'}>
          <TitleWidget
            fontSize={14}
            title={t('Action_Required_Robots')}
            icon={AiFillSound}
            iconPosition={'left'}
            iconSize={20}
            iconMarginL={10}
            padding={'0px 10px 10px 10px'}
            useLink={false}
          />
        </div>
        <div className={'ml-1 content-center'}>
          <DateLabel
            fontSize={11}
            dateFormat={'YYYY-MM-DD hh:mm:ss'}
            useClock={true}
          />
        </div>
        <div className={'content-center ml-auto'}>
          <TitleWidget
            fontSize={13}
            title={t('more')}
            icon={RiExternalLinkFill}
            iconPosition={'left'}
            iconSize={16}
            iconMarginL={3}
            padding={'0px 10px 10px 10px'}
            useLink={true}
            linkAddress={'/robot'}
          />
        </div>
      </div>
      <div className={'mt-4 w-full flex-grow'}>
        <Grid<ActionRequiredRobotsType>
          headerHeight={40}
          rowData={rowData}
          columnDefs={columnDefs}
          height={175}
          noRowsText={t('There_are_no_robots_requiring_action')}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default ActionRequiredRobotsGrid;
