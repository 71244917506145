import { ReturnData } from '../../_types';
import { DoneTaskList } from '../../_types/schedule';
import core from '../../config/axios';

interface Params {
  siteId: string;
  taskId: string;
}
interface StopParams extends Params {
  activation: 'START' | 'STOP' | 'USER_STOP';
}

interface siteWithDatePrams {
  siteId: string;
  startDate: string;
  endDate: string;
}

const { client, isClient } = core;

export async function postStopTask(data: StopParams): Promise<any> {
  try {
    const res = await isClient.post(`/task/command`, data);

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('태스크 중지 에러입니다.');
  }
}

export async function deleteTask(data: Params): Promise<ReturnData> {
  try {
    const res = await isClient.delete(`/task/delete`, { data });

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('태스크 삭제 에러입니다.');
  }
}

export async function doneTask(
  data: siteWithDatePrams,
): Promise<DoneTaskList[]> {
  try {
    const res = await isClient.get(
      `/task/complete-list?siteId=${data.siteId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    );

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('완료된 스케줄을 불러오지 못했습니다.');
  }
}

export async function getSiteScheduleData(siteIds: string): Promise<any> {
  try {
    const res = await client.get(`schedule/all/by-sites?siteIds=${siteIds}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('사이트별 스케줄 데이터를 불러오지 못했습니다.');
  }
}

export async function getScheduleData(scheduleId: string): Promise<any> {
  try {
    const res = await client.get(`schedule/one/${scheduleId}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('스케줄 데이터를 불러오지 못했습니다.');
  }
}

export async function postScheduleData(scheduleData: Object): Promise<any> {
  try {
    const res = await client.post('/schedule', scheduleData);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('스케줄 데이터를 저장하지 못했습니다.');
  }
}

export async function putScheduleData(scheduleData: Object): Promise<any> {
  try {
    const res = await client.put('/schedule', scheduleData);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('스케줄 데이터를 수정하지 못했습니다.');
  }
}

export async function deleteScheduleData(scheduleId: string): Promise<any> {
  try {
    const res = await client.delete(`/schedule/${scheduleId}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('스케줄 데이터를 삭제하지 못했습니다.');
  }
}

export async function syncRedisData(): Promise<any> {
  try {
    const res = await client.get(`/robot/master-devices`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('redis 데이터를 동기화하지 못했습니다.');
  }
}
