import { useEffect, useRef, useState } from 'react';
import { CurrentTask, ScheduleSocketData } from '../_types';
import { SECOND_SOCKET_URL } from '../_api/webSocket';
import useUserStore from '../store/userStore';
import { Socket, io } from 'socket.io-client';
import { toast } from 'react-toastify';
import useRobotstore from '../store/robotStore';

interface PropsType {
  type: 'sites' | 'devices';
}

const useTaskData = ({
  type,
}: PropsType): {
  taskSiteSocketData: ScheduleSocketData[];
  taskDeviceSocketData: CurrentTask[];
} => {
  const { selectedUserAuthSite, userStatus } = useUserStore();
  const { selectedRobot } = useRobotstore();

  const [taskSiteSocketData, setTaskSiteSocketData] = useState<
    ScheduleSocketData[]
  >([]);
  const [taskDeviceSocketData, setTaskDeviceSocketData] = useState<
    CurrentTask[]
  >([]);

  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    if (
      !socketRef.current ||
      userStatus === null ||
      (type === 'sites' && selectedUserAuthSite.length === 0) ||
      (type === 'devices' && selectedRobot === null)
    )
      return;

    socketRef.current.emit('request', {
      ...(type === 'sites' && { sites: selectedUserAuthSite }),
      ...(type === 'devices' && { devices: [selectedRobot] }),
    });

    socketRef.current.on('datas', (data) => {
      type === 'sites'
        ? setTaskSiteSocketData(data as ScheduleSocketData[])
        : setTaskDeviceSocketData(data as CurrentTask[]);
    });
  }, [socketRef.current, selectedUserAuthSite, selectedRobot, userStatus]);

  useEffect(() => {
    const taskSocket = io(`${SECOND_SOCKET_URL}/all-tasks`, {
      transports: ['websocket'],
      upgrade: true,
    });

    taskSocket.on('connect', () => {
      console.log('task socket data connected.');
    });

    taskSocket.on('connect_error', (err) => {
      console.error('socket connection error : ', err);
      toast.error('데이터 연결에 문제가 발생했습니다.');
    });

    socketRef.current = taskSocket;

    return () => {
      taskSocket.disconnect();
      taskSocket.off('connect');
      taskSocket.off('datas');
    };
  }, []);

  return { taskSiteSocketData, taskDeviceSocketData };
};

export default useTaskData;
