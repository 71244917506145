import { useEffect, useState } from 'react';
import '../../../style/animation.css';

import { DeviceDataType, ElevatorCommandList } from '../../../_types';
import EVJobOrderButton from './EVJobOrderButton';
import useRobotstore from '../../../store/robotStore';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { getRobotStateColor } from '../../../util/styleString';
import { Button } from 'antd';
import { TbRefresh } from 'react-icons/tb';
import { syncRedisData } from '../../../_api/rest/schedule';
import { toast } from 'react-toastify';

type EVDetailProps = {
  socketData: DeviceDataType[];
};

interface ElevatorTypeWithSocket extends DeviceDataType {
  manufacturer?: string;
  data?: { weightState: string };
  commandList?: ElevatorCommandList[];
}

export default function EVDetail({ socketData }: EVDetailProps) {
  const [cmdList, setCmdList] = useState<ElevatorCommandList[]>([]);
  const { selectedRobot, selectedRobotDetailData, updateIsShowRobotDetail } =
    useRobotstore();

  const [socketWithElevatorData, setSocketWithElevatorData] =
    useState<ElevatorTypeWithSocket>();
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const handleClickRefresh = async () => {
    setRefreshing(true);

    try {
      await syncRedisData();
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setTimeout(() => {
        setRefreshing(false);
      }, 400);
    }
  };

  const closeMenu = () => {
    updateIsShowRobotDetail(false);
  };

  useEffect(() => {
    if (socketData.length !== 0) {
      const tempSelectedRobot = socketData.find(
        (item: DeviceDataType) => item.deviceId === selectedRobot,
      );
      setSocketWithElevatorData(tempSelectedRobot);
    }
  }, [socketData, selectedRobot]);

  useEffect(() => {
    if (!socketWithElevatorData) return;
    setCmdList(socketWithElevatorData.commandList as ElevatorCommandList[]);
  }, [socketWithElevatorData]);

  return (
    <>
      {socketWithElevatorData && (
        <>
          <div
            onClick={closeMenu}
            className="self-end text-black hover:cursor-pointer"
          >
            ✕
          </div>
          <div className="text-2xl">{t('Detailed_Information')}</div>
          <div className="mt-6 mb-7">
            <div className="mb-2 text-sm font-bold">
              {t('Basic_Information')}
            </div>
            <div className="text-center text-xs w-[98%]">
              <div className="flex font-bold rounded-lg shadow-sm bg-neutral-200">
                <div className="w-[120px] p-2">{t('Type')}</div>
                <div className="w-[120px] p-2">{t('Manufacturer')}</div>
                <div className="w-[170px] p-2">{t('Building_Name')}</div>
                <div className="w-[150px] p-2">{t('Designation')}</div>
              </div>
              <div>
                <div
                  key={socketWithElevatorData?.id}
                  className="flex items-center mt-2 bg-white rounded-lg shadow-sm"
                >
                  <div className="w-[120px] p-2">
                    {socketWithElevatorData?.modelType ?? '-'}
                  </div>
                  <div className="w-[120px] p-2">
                    {socketWithElevatorData?.manufacturer ?? '-'}
                  </div>
                  <div className="w-[170px] p-2">
                    {socketWithElevatorData?.standardLocation.buildingIndex ??
                      '-'}
                  </div>
                  <div className="w-[150px] p-2">
                    {socketWithElevatorData?.name ?? '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-7">
            <div className="mb-2 text-sm font-bold">{t('status')}</div>
            <div className="text-center text-xs w-[98%] mt-3 mb-7">
              <div className="flex font-bold rounded-lg shadow-sm bg-neutral-200">
                <div className="w-[120px] p-2">{t('status')}</div>
                <div className="w-[160px] p-2">{t('Current_Task')}</div>
                <div className="w-[150px] p-2">{t('Internal_Weight')}</div>
                <div className="w-[120px] p-2">{t('Data_Update_Time')}</div>
              </div>
              <div>
                <div
                  key={selectedRobotDetailData?.id}
                  className="flex items-center mt-2 bg-white rounded-lg shadow-sm"
                >
                  <div className="w-[120px] p-2">
                    <span
                      className={`${getRobotStateColor(socketWithElevatorData.robotStateColor)}`}
                    >
                      ●
                    </span>
                  </div>

                  <div className="w-[160px] p-2">
                    {socketWithElevatorData?.robotStateString ?? '-'}
                  </div>
                  <div className="w-[150px] p-2">
                    {socketWithElevatorData?.data?.weightState ?? '-'}
                  </div>
                  <div className="w-[120px] p-2">
                    {socketWithElevatorData.lastUpdateTime
                      ? dayjs(socketWithElevatorData.lastUpdateTime).format(
                          'MM-DD HH:mm:ss',
                        )
                      : '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {cmdList && cmdList.length > 0 && (
        <div className="mt-3 mb-7">
          <div className="flex content-center">
            <div className="pb-2 text-sm font-bold pt-[2px]">
              {t('Work_Order')}
            </div>
            <Button
              className="ml-[10px]"
              size="small"
              onClick={handleClickRefresh}
              type="primary"
              ghost
            >
              <TbRefresh
                size="18"
                color={'#08B295'}
                className={refreshing ? 'animate-spin-fast' : ''}
              />
            </Button>
          </div>
          <EVJobOrderButton
            isOnline={
              selectedRobotDetailData?.isOnline ||
              selectedRobotDetailData?.robotStateColor === 'GREEN'
            }
            cmdList={cmdList.sort((a, b) => a.order - b.order)}
          />
        </div>
      )}
    </>
  );
}
