import { useEffect, useRef, useState } from 'react';

import {
  AuthRobotType,
  DeviceDataType,
  FirestoreCollenctions,
  RobotType,
  SimpleRobotType,
} from '../_types';
import { SECOND_SOCKET_URL } from '../_api/webSocket';
import useUserStore from '../store/userStore';

import { Socket, io } from 'socket.io-client';
import { toast } from 'react-toastify';
import { doc, DocumentData, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import useUserData from './useUserData';

export default function useRobotData() {
  const { selectedUserAuthSite, userStatus } = useUserStore();

  const [robotSocketData, setRobotSocketData] = useState<DeviceDataType[]>([]);

  const socketRef = useRef<Socket | null>(null);

  const { user } = useUserData();

  const [robotList, setRobotList] = useState<AuthRobotType[]>([]);

  async function getAuthRobotList() {
    if (!user) return;

    const robots: AuthRobotType[] = [];

    const resList: DocumentData | undefined[] = [];

    // robot list
    await Promise.all(
      user.auth_robots.map(async (currentRobot) => {
        const docRef = doc(
          db,
          FirestoreCollenctions.ROBOTS,
          currentRobot as any,
        );
        const res = await getDoc(docRef);
        resList.push(res.data());
        robots.push({ deviceId: res.data()?.device_id as string });
      }),
    );

    setRobotList(robots);
    return robots;
  }

  useEffect(() => {
    getAuthRobotList();
  }, [user]);

  useEffect(() => {
    if (
      !socketRef.current ||
      selectedUserAuthSite.length === 0 ||
      userStatus === null
    )
      return;

    socketRef.current.emit('sites', selectedUserAuthSite);

    socketRef.current.on('robots', (data) => {
      if (userStatus !== 0) {
        const matchingRobots = data.filter((robot: AuthRobotType) =>
          robotList.some(
            (item: AuthRobotType) => item.deviceId === robot.deviceId,
          ),
        );

        setRobotSocketData(matchingRobots);
      } else {
        setRobotSocketData(data);
      }
    });
  }, [socketRef.current, selectedUserAuthSite, userStatus]);

  useEffect(() => {
    const robotSocket = io(`${SECOND_SOCKET_URL}/robots`, {
      transports: ['websocket'],
      upgrade: true,
    });

    robotSocket.on('connect', () => {
      console.log('robot socket data connected.');
    });

    robotSocket.on('connect_error', (err) => {
      console.error('socket connection error : ', err);
      toast.error('데이터 연결에 문제가 발생했습니다.');
    });

    socketRef.current = robotSocket;

    return () => {
      robotSocket.disconnect();
      robotSocket.off('connect');
      robotSocket.off('robots');
    };
  }, []);

  return { robotSocketData };
}
