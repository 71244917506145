import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

import useAuth from '../hooks/useAuth';
import usePeriod, { dateFormat, getToday } from '../hooks/usePeriod';

import InquiryModal from '../components/modal/InquiryModal';
import ChartContainer from '../components/dashboard/ChartContainer';
import NewChartContainer from '../components/dashboard/NewChartContainer';
import { PeriodType } from '../_types/dashBoard';
import SearchInput from '../components/common/SearchInput';
import { Button, Switch } from 'antd';
import '../style/schedule.css';
import { BiSolidWidget } from 'react-icons/bi';
import { SettingWidgetContainer } from '../components/dashboard/SettingWidgetContainer';
import dayjs from 'dayjs';

export default function DashboardPage() {
  dayjs.locale('en');

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { hasAuthSite, hasAuthRobot, login } = useAuth();
  const { startDate, endDate } = usePeriod();

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(lastMonth.getDate() + 1);

  const [period, setPeriod] = useState<PeriodType>({
    startDate: dateFormat(lastMonth),
    endDate: getToday(),
  });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [openSettingWidget, setOpenSettingWidget] = useState<boolean>(false);

  useEffect(() => {
    setPeriod({ startDate, endDate });
  }, [startDate, endDate]);

  const handleChangeDate = (newValue: DateValueType) => {
    navigate(
      `/dashboard?start=${newValue?.startDate}&end=${newValue?.endDate}`,
    );
  };

  const onEditMode = (checked: boolean) => {
    console.log('checked = ', checked);
    setEditMode(checked);
  };

  const openSettingWidgetContainer = () => {
    setOpenSettingWidget(true);
  };

  return (
    <div className="w-[100%] pl-[75px] bg-neutral-50">
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t('dashboard')}
      </div>

      <section
        className="pt-[90px] flex items-center justify-between pb-3 pl-12"
        data-name="bg"
      >
        <SearchInput viewOption={'all'} />

        <div className="pr-12 min-w-[300px]">
          <Datepicker
            value={period}
            inputClassName={
              'w-full  py-2 px-3 text-sm focus:bg-[#ebf7f6] placeholder:text-sm rounded-lg sm:text-sm shadow-sm '
            }
            placeholder={t('please_select_a_period')}
            primaryColor={'teal'}
            useRange={false}
            showShortcuts={true}
            onChange={handleChangeDate}
            i18n={`${i18next.resolvedLanguage}`}
            dateLooking="middle"
            configs={{
              shortcuts: {
                today: `${t('today')}`,
                yesterday: `${t('yesterday')}`,
                currentMonth: `${t('currentMonth')}`,
                pastMonth: `${t('pastMonth')}`,
                past: (period) => `${t('the_last')} ${period}${t('days')}`,
              },
            }}
          />
        </div>
      </section>
      {/*<div className="flex justify-end h-10 pr-12">*/}
      {/*  <div className={'mr-5 flex items-center'}>*/}
      {/*    <label>편집모드</label>*/}
      {/*    <Switch*/}
      {/*      onChange={onEditMode}*/}
      {/*      value={editMode}*/}
      {/*      className={'scheduleSwitch'}*/}
      {/*      style={{ marginLeft: '5px' }}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  {editMode && (*/}
      {/*    <div className={'mr-2'}>*/}
      {/*      <Button*/}
      {/*        icon={<BiSolidWidget />}*/}
      {/*        type={'primary'}*/}
      {/*        className={'flex items-center'}*/}
      {/*      >*/}
      {/*        저장*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  <div>*/}
      {/*    <Button*/}
      {/*      icon={<BiSolidWidget />}*/}
      {/*      className={'flex items-center'}*/}
      {/*      onClick={openSettingWidgetContainer}*/}
      {/*    >*/}
      {/*      위젯추가*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <ChartContainer period={period} />
      {/*<NewChartContainer editMode={editMode} />*/}

      {/*<SettingWidgetContainer*/}
      {/*  closeSetting={setOpenSettingWidget}*/}
      {/*  open={openSettingWidget}*/}
      {/*/>*/}
    </div>
  );
}
