import React, { useState } from 'react';
import { Grid } from '../../dashboard/common/grid/grid';
import { ColDef } from 'ag-grid-community';
import { TitleWidget } from '../../dashboard/common/label/titleWidget';
import { DateLabel } from '../../dashboard/common/label/dateLabel';
import { IoBarChart } from 'react-icons/io5';
import { RiAlarmWarningFill, RiExternalLinkFill } from 'react-icons/ri';
import { AiFillAlert } from 'react-icons/ai';

const DailyErrorLogGrid = () => {
  interface RowData {
    work: string | null;
    robot: string | null;
    comment: string | null;
  }

  const [rowData] = useState<RowData[]>([
    {
      work: '청소 구역 1-2-3 이동 반복 청소',
      robot: '가우시움 판타스',
      comment: '로봇 CMD 이슈',
    },
    { work: '손님 서빙 작업', robot: '마로솔 서빙', comment: '타임아웃 에러' },
    {
      work: '입구에서 지점까지 안내 작업',
      robot: 'CLOi 시연용',
      comment: '타임아웃 에러',
    },
    { work: '물류 상하차 업무', robot: '물류로봇', comment: '사용자 중지' },
    {
      work: '서비스로봇팀 안내코스',
      robot: 'CLOi 시연용',
      comment: '타임아웃 에러',
    },
  ]);

  const [columnDefs] = useState<ColDef<RowData>[]>([
    {
      headerName: '작업명',
      field: 'work',
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 1,
    },
    {
      headerName: '로봇명',
      field: 'robot',
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 1,
    },
    {
      headerName: '세부 내용',
      field: 'comment',
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 1,
    },
  ]);

  return (
    <div className={'p-5'}>
      <div className={'flex border-b-[1px] border-gray-100'}>
        <div className={'content-center'}>
          <TitleWidget
            fontSize={17}
            title={'일일 오류 이력'}
            icon={AiFillAlert}
            iconPosition={'left'}
            iconSize={20}
            iconMarginL={10}
            padding={'0px 10px 10px 10px'}
            width={150}
            useLink={false}
          />
        </div>
        <div className={'ml-1 content-center'}>
          <DateLabel
            fontSize={11}
            dateFormat={'YYYY-MM-DD hh:mm:ss'}
            useClock={false}
          />
        </div>
        <div className={'content-center ml-auto'}>
          <TitleWidget
            fontSize={13}
            title={'더보기'}
            icon={RiExternalLinkFill}
            iconPosition={'left'}
            iconSize={16}
            iconMarginL={3}
            padding={'0px 10px 10px 10px'}
            useLink={true}
            linkAddress={'/dashboard'}
          />
        </div>
      </div>
      <div className={'mt-5 w-full'}>
        <Grid rowData={rowData} columnDefs={columnDefs} height={300} />
      </div>
    </div>
  );
};

export default DailyErrorLogGrid;
