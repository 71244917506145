import React, { useEffect, useState } from 'react';
import { AiFillSchedule, AiOutlineBars, AiOutlineStop } from 'react-icons/ai';
import { FaListCheck, FaUserCheck } from 'react-icons/fa6';
import {
  MdCancelScheduleSend,
  MdEventRepeat,
  MdLibraryAddCheck,
  MdOutlineCleaningServices,
  MdOutlineDoorSliding,
  MdOutlineStopScreenShare,
  MdPermMedia,
} from 'react-icons/md';
import { PiElevatorFill } from 'react-icons/pi'; //hall call
import { FaElevator } from 'react-icons/fa6'; //car call
import { BiAlarmExclamation, BiDoorOpen, BiSolidTimer } from 'react-icons/bi';
import {
  RiArchiveDrawerLine,
  RiDoorClosedLine,
  RiRemoteControlLine,
} from 'react-icons/ri';
import { BsDatabaseFillCheck } from 'react-icons/bs';
import {
  IoCaretForwardCircle,
  IoMoveSharp,
  IoStopCircle,
} from 'react-icons/io5';
import { RiBattery2ChargeFill } from 'react-icons/ri';
import { BsSignStopFill } from 'react-icons/bs';
import { MdHome } from 'react-icons/md';
import {
  TbEaseInOutControlPoints,
  TbEaseOutControlPoint,
  TbStairsUp,
} from 'react-icons/tb';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { Collapse, Divider } from 'antd';
import { text } from 'node:stream/consumers';
import useWorkflowStore from '../../store/workflowStore';
import errorPage from '../../pages/ErrorPage';
import { getBuilderNodeList } from '../../_api/rest/workflow';
import useUserStore from '../../store/userStore';
import { ImDrawer } from 'react-icons/im';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;
/*SVG*/

const ItemBuilder = (props: any) => {
  const { robotInfo, robotType, changeRobotInfo, changeSiteNodeInfoList } =
    useWorkflowStore();
  const { selectedUserAuthSite } = useUserStore();
  const [nodeList, setNodeList] = useState<any>([]);

  const [activeKeys, setActiveKeys] = useState<string | string[]>([]);

  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const { t, i18n } = useTranslation();
  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('id', event.target.id);
    event.dataTransfer.effectAllowed = 'move';
  };

  useEffect(() => {
    if (props.isReset) {
      setNodeList([]);
    }
  }, [props.isReset]);

  useEffect(() => {
    props.loadingStatus('builder', apiLoading);
  }, [apiLoading]);

  useEffect(() => {
    if (robotType === 'single' && selectedUserAuthSite[0] && robotInfo) {
      setApiLoading(true);

      (async () => {
        await getNodeListAPI(selectedUserAuthSite[0], robotInfo.deviceId);
      })();
    }

    if (robotType === 'multiple' && selectedUserAuthSite[0] && !robotInfo) {
      setApiLoading(true);

      (async () => {
        await getNodeListAPI(selectedUserAuthSite[0], '');
      })();
    }

    if (props.reRender) {
      props.resetReRender(false, 'builder');
    }
  }, [robotType, robotInfo, selectedUserAuthSite, props.reRender]);

  useEffect(() => {
    if (robotType === 'single') {
      changeRobotInfo(null);
      setNodeList([]);
    }
  }, [robotType]);

  const getNodeListAPI = async (siteId: string, deviceId: string) => {
    await getBuilderNodeList(siteId, deviceId)
      .then((res: any) => {
        if (res !== null && res !== undefined) {
          setNodeList(res);
          changeSiteNodeInfoList(res);
          setActiveKeys([]);
          setApiLoading(false);
        }
      })
      .catch((error: string) => {
        errorPage();
      });
  };

  useEffect(() => {
    if (selectedUserAuthSite.length === 0) {
      setNodeList([]);
      changeSiteNodeInfoList([]);
    }
  }, [selectedUserAuthSite[0]]);

  const iconMapping = (action: string) => {
    // const name = action.replace('NODE_', '');

    switch (action) {
      case 'MOVE': {
        return <IoMoveSharp size="20" color="rgb(100, 100, 100)" />;
      }
      case 'CHARGE': {
        return <RiBattery2ChargeFill size="20" color="rgb(100, 100, 100)" />;
      }
      case 'HOME': {
        return <MdHome size="20" color="rgb(100, 100, 100)" />;
      }
      case 'HALL_CALL': {
        return <PiElevatorFill size="20" color="rgb(100, 100, 100)" />;
      }
      case 'CAR_CALL': {
        return <FaElevator size="20" color="rgb(100, 100, 100)" />;
      }
      case 'OPEN_DOOR': {
        return <BiDoorOpen size="20" color="rgb(100, 100, 100)" />;
      }
      case 'KEEP_OPEN': {
        return <RiDoorClosedLine size="20" color="rgb(100, 100, 100)" />;
      }
      case 'CHECK': {
        return <FaListCheck size="20" color="rgb(100, 100, 100)" />;
      }
      case 'CHECK_SELECT': {
        return <MdLibraryAddCheck size="20" color="rgb(100, 100, 100)" />;
      }
      case 'SET_VALUE': {
        return <BsDatabaseFillCheck size="20" color="rgb(100, 100, 100)" />;
      }
      case 'IO_CONTROL': {
        return (
          <TbEaseInOutControlPoints size="20" color="rgb(100, 100, 100)" />
        );
      }
      case 'WAIT': {
        return <BiSolidTimer size="20" color="rgb(100, 100, 100)" />;
      }
      case 'ON_TIME': {
        return <MdEventRepeat size="20" color="rgb(100, 100, 100)" />;
      }
      case 'USER_SELECT': {
        return <FaUserCheck size="20" color="rgb(100, 100, 100)" />;
      }
      case 'AUTO_DOOR_OPEN': {
        return <MdOutlineDoorSliding size="20" color="rgb(100, 100, 100)" />;
      }
      case 'AUTO_DOOR_CLOSE': {
        return <MdOutlineDoorSliding size="20" color="rgb(100, 100, 100)" />;
      }
      case 'CLEAN': {
        return (
          <MdOutlineCleaningServices size="20" color="rgb(100, 100, 100)" />
        );
      }
      case 'CONTENTS_PLAY': {
        return <MdPermMedia size="20" color="rgb(100, 100, 100)" />;
      }
      case 'CHANGE_MAP': {
        return <FaMapMarkedAlt size="20" color="rgb(100, 100, 100)" />;
      }
      case 'CHANGE_FLOOR': {
        return <TbStairsUp size="20" color="rgb(100, 100, 100)" />;
      }
      case 'APP_CONTROL': {
        return <RiRemoteControlLine size="20" color="rgb(100, 100, 100)" />;
      }
      case 'OUT_CONTROL': {
        return <TbEaseOutControlPoint size="20" color="rgb(100, 100, 100)" />;
      }
      case 'BACK_TO_SCHEDULE':
        return <MdCancelScheduleSend size="20" color="rgb(100, 100, 100)" />;
      case 'APP_STOP': {
        return <BsSignStopFill size="20" color="rgb(100, 100, 100)" />;
      }
      case 'STOP': {
        return <AiOutlineStop size="20" color="rgb(100, 100, 100)" />;
      }
      case 'CONTROL_DRAWER': {
        return <RiArchiveDrawerLine size="20" color="rgb(100, 100, 100)" />;
      }
      case 'NOTICE_ALARM': {
        return <BiAlarmExclamation size="20" color="rgb(100, 100, 100)" />;
      }
      default: {
        return <BsSignStopFill size="20" color="rgb(100, 100, 100)" />;
      }
    }
  };

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKeys(key);
  };

  return (
    <aside className={'wf-body-right-contents'}>
      <>
        <div
          id="START/END|START|START|시작"
          draggable={true}
          onDragStart={(event) => onDragStart(event, 'input')}
        >
          <div style={styles.parent}>
            <div style={styles.iconParent}>
              <IoCaretForwardCircle size="20" color="rgb(100, 100, 100)" />
            </div>
            {t('start')}
          </div>
        </div>

        <div
          id="START/END|END|END|종료"
          draggable={true}
          onDragStart={(event) => onDragStart(event, 'output')}
        >
          <div style={styles.parent}>
            <div style={styles.iconParent}>
              <IoStopCircle size="20" color="rgb(100, 100, 100)" />
            </div>
            {t('end')}
          </div>
        </div>
      </>

      <Collapse
        className={'mt-5 border-none bg-none'}
        defaultActiveKey={['1']}
        activeKey={activeKeys}
        onChange={handleCollapseChange}
      >
        {nodeList.map((item: any, idx: number) => {
          if (item.nodeGroupManufacturer === 'COMMON') {
            return (
              <Panel
                className={'bg-white border-none'}
                header={`${item.nodeGroupInfo.modelNameEn}(${item.nodeGroupInfo.modelNameKr})`}
                key={'key' + idx}
              >
                {item.nodeList.map((data: any, i: number) => {
                  const nodeKey = data.nodeName.replace('NODE_', '');

                  return (
                    <div
                      key={`node_${i}`}
                      id={`COMMON|${nodeKey}|${data.nodeNameEn}|${data.nodeNameKr}|${item.nodeGroupManufacturer}|${item.nodeGroupVersion}`}
                      draggable={true}
                      onDragStart={(event) => onDragStart(event, 'default')}
                    >
                      <div style={styles.parent}>
                        <div style={styles.iconParent}>
                          {iconMapping(nodeKey)}
                        </div>
                        {i18n.resolvedLanguage === 'ko'
                          ? data.nodeNameKr
                          : data.nodeNameEn}
                      </div>
                    </div>
                  );
                })}
              </Panel>
            );
          } else {
            return (
              <Panel
                className={'bg-white border-none'}
                header={
                  <>
                    {item.nodeGroupInfo.manufacturerKr} (
                    {item.nodeGroupInfo.modelNameKr})
                    <br />
                    <div
                      className={
                        'h-[24px] bg-[#26bfa1] text-white rounded-[6px] text-center content-center inline-block px-[7px]'
                      }
                    >
                      {i18n.resolvedLanguage === 'ko'
                        ? item.nodeGroupInfo.modelTypeKr
                        : item.nodeGroupInfo.modelTypeEn}
                    </div>
                  </>
                }
                key={'key' + idx}
              >
                {item.nodeList.map((data: any, i: number) => {
                  const nodeKey = data.nodeName.replace('NODE_', '');

                  return (
                    <div
                      key={`node_${i}`}
                      id={`${item.nodeGroupModelType}|${nodeKey}|${data.nodeNameEn}|${data.nodeNameKr}|${item.nodeGroupManufacturer}|${item.nodeGroupModelName}|${item.nodeGroupModelUuid}`}
                      draggable={true}
                      onDragStart={(event) => onDragStart(event, 'default')}
                    >
                      <div style={styles.parent}>
                        <div style={styles.iconParent}>
                          {iconMapping(nodeKey)}
                        </div>
                        {i18n.resolvedLanguage === 'ko'
                          ? data.nodeNameKr
                          : data.nodeNameEn}
                      </div>
                    </div>
                  );
                })}
              </Panel>
            );
          }
        })}
      </Collapse>
    </aside>
  );
};

export default ItemBuilder;

const styles = {
  parent: {
    padding: 7,
    fontSize: 14,
    background: '#fff',
    cursor: 'move',
    marginTop: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ddd',
    fontFamily: 'Gmarket Sans, Medium',
  },
  iconParent: {
    borderRight: '1px solid rgb(230, 230, 230)',
    padding: 5,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
  },
  splitIcon: {
    transform: 'rotate(90deg)',
  },
  joinIcon: {
    transform: 'rotate(-90deg)',
  },
  sidebarItemHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: '10px',
    lineHeight: 'normal',
  },
  sidebarItemHeadingContainer: {
    marginTop: 24,
    marginBottom: 12,
  },
};
