import { useEffect, useRef } from 'react';

export type TCoordinates = { x: number; y: number };

export interface RobotInfo {
  coordinate: TCoordinates;
  robotStateColor: string;
  shadow: boolean;
  nameKr?: string;
  robotStateString?: string;
}

interface UseCanvasProps {
  robotInfo: RobotInfo[];
  imageSrc: string;
  multiple: number;
  speed: number; // 속도 (예: 픽셀/초 단위)
}

const INITIAL_POSITION = { x: 0, y: 0 };

const useCanvas = ({
  robotInfo,
  imageSrc,
  multiple,
  speed,
}: UseCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const scaleRef = useRef<number>(1);
  const viewPosRef = useRef<TCoordinates>(INITIAL_POSITION);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const currentPositionsRef = useRef<Map<string, TCoordinates>>(new Map());
  const animationFrameRef = useRef<number | null>(null);
  const robotInfoRef = useRef(robotInfo); // robotInfo를 useRef로 관리
  const hasInitializedPositions = useRef(false); // 초기 위치 설정 여부
  const lastFrameTimeRef = useRef(performance.now()); // 이전 프레임 시간

  // robotInfo 변경 시마다 최신값을 robotInfoRef에 업데이트
  useEffect(() => {
    robotInfoRef.current = robotInfo;

    // 로봇 좌표 초기 설정 (처음 한 번만 실행)
    if (!hasInitializedPositions.current) {
      robotInfo.forEach((info) => {
        currentPositionsRef.current.set(info.nameKr || '', info.coordinate);
      });
      hasInitializedPositions.current = true;
    }
  }, [robotInfo]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const image = new Image();
    imageRef.current = image;
    image.src = imageSrc;
    image.crossOrigin = 'Anonymous';

    const getCircleColor = (color: string) => {
      switch (color) {
        case 'RED':
          return '#FF4040';
        case 'GREEN':
          return '#26BFA1';
        case 'ORANGE':
          return '#FAAD14';
        case 'GRAY':
          return '#9ca3af';
        default:
          return '#9ca3af';
      }
    };

    const setTransform = (ctx: CanvasRenderingContext2D) => {
      ctx.setTransform(
        scaleRef.current,
        0,
        0,
        scaleRef.current,
        viewPosRef.current.x,
        viewPosRef.current.y,
      );
    };

    const animate = () => {
      const currentTime = performance.now();
      const deltaTime = (currentTime - lastFrameTimeRef.current) / 1000; // 초 단위로 계산
      lastFrameTimeRef.current = currentTime;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setTransform(ctx);

      // 배경 이미지 그리기
      if (imageRef.current) {
        ctx.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height);
      }

      // 각 로봇을 일정한 속도로 부드럽게 이동
      robotInfoRef.current.forEach((info) => {
        const targetPosition = info.coordinate;

        // 초기 위치가 없으면 targetPosition으로 설정하여 애니메이션 없이 그 위치에서 시작
        if (!currentPositionsRef.current.has(info.nameKr || '')) {
          currentPositionsRef.current.set(info.nameKr || '', targetPosition);
        }

        // 현재 위치를 가져오기
        const prevPosition =
          currentPositionsRef.current.get(info.nameKr || '') ?? targetPosition;

        // 목표 위치와의 거리 계산
        const dx = targetPosition.x - prevPosition.x;
        const dy = targetPosition.y - prevPosition.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        let currentPos;
        if (distance < speed * deltaTime || distance === 0) {
          // 충분히 가까워졌거나 이미 위치에 있을 경우 목표 좌표로 고정
          currentPos = targetPosition;
        } else {
          // 일정 속도로 이동
          const moveX = (dx / distance) * speed * deltaTime;
          const moveY = (dy / distance) * speed * deltaTime;
          currentPos = {
            x: prevPosition.x + moveX,
            y: prevPosition.y + moveY,
          };
        }

        // 갱신된 위치를 저장하여 다음 프레임에서 참조
        currentPositionsRef.current.set(info.nameKr || '', currentPos);

        // 로봇 마커 그리기
        drawCoordinates(
          ctx,
          currentPos,
          info.robotStateColor,
          info.shadow,
          info.nameKr,
        );
      });

      // 다음 프레임 예약
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    const drawCoordinates = (
      ctx: CanvasRenderingContext2D,
      coordinates: TCoordinates,
      color: string,
      shadow: boolean,
      name?: string,
    ) => {
      if (shadow) {
        ctx.shadowColor = getCircleColor(color);
        ctx.shadowBlur = 30;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
      }

      ctx.fillStyle = getCircleColor(color);
      ctx.beginPath();
      ctx.arc(
        coordinates.x * multiple,
        coordinates.y * multiple,
        5 * multiple,
        0,
        2 * Math.PI,
      );
      ctx.fill();

      // 그림자 초기화
      ctx.shadowColor = 'transparent';
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;

      if (name) {
        ctx.fillStyle = '#fff';
        const fontSize = 6 * multiple;
        ctx.font = `${fontSize}px Arial`;

        const textWidth = ctx.measureText(name).width;

        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;
        ctx.strokeText(
          name,
          coordinates.x * multiple - textWidth / 2,
          coordinates.y * multiple + 15 * multiple,
        );

        ctx.fillStyle = '#fff';
        ctx.fillText(
          name,
          coordinates.x * multiple - textWidth / 2,
          coordinates.y * multiple + 15 * multiple,
        );
      }
    };

    image.onload = () => {
      animate(); // 이미지가 로드된 후 애니메이션 시작
    };

    // 컴포넌트가 언마운트될 때 애니메이션 중지
    return () => {
      if (animationFrameRef.current !== null) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [imageSrc]);

  return { canvasRef };
};

export default useCanvas;
