import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../common/Button';
import Select from '../../common/SingleSelect';

import {
  ElevatorCommand,
  ElevatorCommandList,
  ElevatorFloorList,
} from '../../../_types/elevator';

import { postElevator } from '../../../_api/rest/elevator';
import useRobotstore from '../../../store/robotStore';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
import { getIsRobotReady, onStartJob } from '../../../_api/rest/common';
import { RobotCommandType } from '../../../_types';

type PropsType = {
  isOnline: boolean;
  cmdList: ElevatorCommandList[];
};

export default function EVJobOrderButton({ isOnline, cmdList }: PropsType) {
  const { changeIsLoading } = useRobotstore();

  const [taskId, setTaskId] = useState<string | null>(null);
  const [elvatorCmd, setElevatorCmd] = useState<
    {
      command: keyof typeof ElevatorCommand;
      floorName?: { targetFloor?: ''; startingFloor?: '' };
    }[]
  >([
    {
      command: 'COMMAND_HALL_CALL',
      floorName: { targetFloor: '', startingFloor: '' },
    },
    { command: 'COMMAND_CAR_CALL', floorName: { targetFloor: '' } },
    { command: 'COMMAND_OPEN_DOOR' },
  ]);

  const { selectedRobot } = useRobotstore();
  const navigator = useNavigate();

  useEffect(() => {
    setTaskId(null);
  }, [selectedRobot]);

  const onChangeEV = (e: {
    command: keyof typeof ElevatorCommand;
    floorName: { targetFloor?: string; startingFloor?: string };
  }) => {
    const existingIndex = elvatorCmd.findIndex(
      (item) => item.command === e.command,
    );

    if (existingIndex !== -1) {
      setElevatorCmd((prev) => [
        ...prev.slice(0, existingIndex),
        {
          ...prev[existingIndex],
          floorName: {
            ...prev[existingIndex].floorName,
            ...(e.floorName as {}),
          },
        },
        ...prev.slice(existingIndex + 1),
      ]);
    } else {
      setElevatorCmd((prev) => [
        ...prev,
        { command: e.command, floorName: e.floorName as {} },
      ]);
    }
  };

  const handleButtonClick = async (
    command: RobotCommandType,
    targetFloor?: string,
    startingFloor?: string,
  ) => {
    changeIsLoading(true);

    switch (command) {
      case 'COMMAND_HALL_CALL':
        if (!targetFloor || targetFloor.trim() === '') {
          toast.warn('이동층을 입력해주세요');
          return;
        }
        if (!startingFloor || startingFloor.trim() === '') {
          toast.warn('호출층을 입력해주세요');
          return;
        }
        postElevator(
          selectedRobot as string,
          command,
          targetFloor,
          startingFloor,
        )
          .then((res) => {
            toast.success(`Hall Call ${t('Completed')}`);

            changeIsLoading(false);
          })
          .catch((error) => {
            toast.warn('Hall Call 실패');
            console.error('Error:', error.message);
          });
        break;
      case 'COMMAND_CAR_CALL':
        if (!targetFloor || targetFloor.trim() === '') {
          toast.warn('층을 입력해주세요');
          return;
        }
        postElevator(selectedRobot as string, command, targetFloor)
          .then((res) => {
            toast.success(`Car Call ${t('Completed')}`);

            changeIsLoading(false);
          })
          .catch((error) => {
            toast.warn(`Car Call ${t('Failed')}`);
            console.error('Error:', error.message);
          });
        break;
      case 'COMMAND_OPEN_DOOR':
        postElevator(selectedRobot as string, command)
          .then((res) => {
            toast.success(`Open Door ${t('Completed')}`);

            changeIsLoading(false);
          })
          .catch((error) => {
            toast.warn(`Open Door ${t('Failed')}`);
            console.error('Error:', error.message);
          });
        break;

      case 'COMMAND_TASK':
        {
          const robotData: {
            command: RobotCommandType;
            deviceId: string;
            taskId: string;
          } = {
            command: 'COMMAND_TASK',
            deviceId: selectedRobot as string,
            taskId: taskId as string,
          };

          if (!taskId) {
            toast.info(t('Please_select_a_TASK'));
            return;
          }

          const isReady = await getIsRobotReady(selectedRobot as string);

          if (!isReady) {
            toast.error(t('Currently_engaged_in_another_task'));
            return;
          }

          onStartJob(robotData).then(() => {
            setTaskId(null);
            toast.success(t('Task_request_has_been_completed'));

            changeIsLoading(false);
          });
        }

        break;

      default:
        (() => {
          toast.error(t('No_work_order'));
          navigator('/');
        })();
    }
  };
  return (
    <section className="flex flex-wrap items-end">
      {cmdList &&
        cmdList.map((elevator) => {
          if (elevator.command === 'COMMAND_OPEN_DOOR') {
            return (
              <div key={elevator.command} className="mx-1 ">
                <div className="my-2 text-sm font-bold pb-[20px] ">
                  {t('Door_Opening')}
                </div>
                <div className="inline-flex items-center">
                  <div>
                    <Button
                      label={elevator.nameKr}
                      type="button"
                      primary
                      disabled={!isOnline || !elevator.isActive}
                      onClick={() => handleButtonClick(elevator.command)}
                    />
                  </div>
                </div>
              </div>
            );
          }
          if (elevator.command === 'COMMAND_HALL_CALL') {
            return (
              <div key={elevator.command} className="mx-1">
                <div className="my-2 text-sm font-bold">{`${t('Elevator')} ${
                  ElevatorCommand[elevator.command]
                }`}</div>
                <div className="flex items-end ">
                  <div className=" flex w-[180px] gap-3 items-end">
                    <div className="w-full pb-1 ">
                      <span className="pl-[2px] text-[12px]">
                        {t('Calling_Floor')}
                      </span>
                      <Select
                        key={elevator.command}
                        label={t('floor')}
                        selected={
                          elvatorCmd.find(
                            (item) => item.command === elevator.command,
                          )?.floorName?.startingFloor as string
                        }
                        disabled={!isOnline || !elevator.isActive}
                        list={elevator.elvFloorList as ElevatorFloorList[]}
                        onChange={(floorName) =>
                          onChangeEV({
                            command: elevator.command,
                            floorName: { startingFloor: floorName },
                          })
                        }
                      />
                    </div>
                    <div className="w-full pb-1 ">
                      <span className="text-[12px] pl-[2px]">
                        {t('Moving_Floor')}
                      </span>
                      <Select
                        key={elevator.command}
                        label={t('floor')}
                        selected={
                          elvatorCmd.find(
                            (item) => item.command === elevator.command,
                          )?.floorName?.targetFloor as string
                        }
                        disabled={!isOnline || !elevator.isActive}
                        list={elevator.elvFloorList as ElevatorFloorList[]}
                        onChange={(floorName) =>
                          onChangeEV({
                            command: elevator.command,
                            floorName: { targetFloor: floorName },
                          })
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <Button
                      label={elevator.nameKr}
                      type="button"
                      primary
                      disabled={!isOnline || !elevator.isActive}
                      onClick={() =>
                        handleButtonClick(
                          elevator.command,
                          elvatorCmd.find(
                            (item) =>
                              (item.command as string) === elevator.command,
                          )?.floorName?.targetFloor as string,
                          elvatorCmd.find(
                            (item) =>
                              (item.command as string) === elevator.command,
                          )?.floorName?.startingFloor as string,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            );
          }
          if (elevator.command === 'COMMAND_CAR_CALL') {
            return (
              <div key={elevator.command} className="mx-1">
                <div className="my-2 text-sm font-bold">{`${t('Elevator')} ${
                  ElevatorCommand[elevator.command]
                }`}</div>
                <div className="inline-flex pt-[20px] items-center">
                  <div className="w-[180px] ">
                    <Select
                      key={elevator.command}
                      label={t('floor')}
                      selected={
                        elvatorCmd.find(
                          (item) => item.command === elevator.command,
                        )?.floorName?.targetFloor as string
                      }
                      disabled={!isOnline || !elevator.isActive}
                      list={elevator.elvFloorList as ElevatorFloorList[]}
                      onChange={(floorName) =>
                        onChangeEV({
                          command: elevator.command,
                          floorName: { targetFloor: floorName },
                        })
                      }
                    />
                  </div>

                  <div>
                    <Button
                      label={elevator.nameKr}
                      type="button"
                      primary
                      disabled={!isOnline || !elevator.isActive}
                      onClick={() =>
                        handleButtonClick(
                          elevator.command,
                          elvatorCmd.find(
                            (item) =>
                              (item.command as string) === elevator.command,
                          )?.floorName?.targetFloor as string,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            );
          }
          if (elevator.command === 'COMMAND_TASK') {
            return (
              <div
                key={elevator.command}
                className="inline-flex items-center ml-[6px] my-0"
              >
                <span className="w-full min-w-[180px]">
                  <Select
                    label={t('Please_select_a_TASK')}
                    selected={taskId as string}
                    disabled={!isOnline || !elevator.isActive}
                    list={elevator.taskList}
                    onChange={(id) => setTaskId(id)}
                  />
                </span>

                <Button
                  label={elevator.nameKr}
                  type="button"
                  primary
                  disabled={!isOnline || !elevator.isActive}
                  onClick={() => handleButtonClick(elevator.command)}
                />
              </div>
            );
          }
        })}
    </section>
  );
}
