import '../../style/schedule.css';
import React, { useEffect, useState } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ko';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { EventClickArg } from '@fullcalendar/core';
import useScheduleData from '../../hooks/useScheduleData';
import { AuthType, PostJobOrderType, scheduleType } from '../../_types';
import useRestRobotData from '../../hooks/useRestRobotData';
import useLoginStore from '../../store/loginStore';
import { getUserById } from '../../_api/firestore/user';
import useScheduleStore from '../../store/scheduleStore';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const SettingCalendar = () => {
  const { i18n } = useTranslation();
  const { userInfo } = useLoginStore();
  const [userData, setUserData] = useState<AuthType | null>(null);
  const [eventList, setEventList] = useState<
    { scheduleId: number; deviceId: string; title: string; start: string }[]
  >([]);

  const { robotData, fetchSiteRobots, findRobotByDeviceId } =
    useRestRobotData(userData);
  const { siteScheduleData } = useScheduleData();

  const {
    updateScheduleId,
    updateDeviceId,
    updateRobotCommand,
    updateWorkStartDate,
  } = useScheduleStore();

  const localizer = momentLocalizer(moment);

  const mapCommandType: any = {
    COMMAND_STOP: '정지',
    COMMAND_HOME: '대기 위치로 이동',
    COMMAND_CHARGE: '충전',
    COMMAND_MOVE: '이동',
    COMMAND_TTS: '문자 보내기',
    COMMAND_VIDEO: '동영상 보내기',
    COMMAND_REBOOT: '재부팅',
    COMMAND_TURN: '자세 제어',
    COMMAND_SOUND: '볼륨 조절',
    COMMAND_BACK_TO_SCHEDULE: '스케줄 복귀',
    COMMAND_PAUSE: '일시정지',
    COMMAND_REFRESH: '관리자 복귀',
    COMMAND_MACRO: '매크로',
    COMMAND_CLEAN: '청소시작',
    COMMAND_REPORT: '레포트',
    COMMAND_CONTENTS_PLAY: '콘텐츠 재생',
    COMMAND_DOCKING: '도킹',
    COMMAND_UNDOCKING: '언도킹',
    COMMAND_HALL_CALL: '홀콜',
    COMMAND_CAR_CALL: '카콜',
    COMMAND_OPEN_DOOR: '문열림',
    COMMAND_TASK: '태스크',
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <>
        <div
          id="eventId"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <span style={{ color: '#166bdb' }}>[{eventInfo.timeText}]</span>{' '}
          <br /> {eventInfo.event.title}
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!userInfo) return;
    const fetchData = async () => {
      const data = await getUserById(userInfo.uid);
      setUserData(data);
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    const eventData: {
      scheduleId: number;
      deviceId: string;
      robotCommand: PostJobOrderType;
      title: string;
      start: string;
    }[] = [];

    siteScheduleData?.forEach(
      ({
        scheduleId,
        deviceId,
        repeatedDates,
        startDate,
        startTime,
        robotCommand,
      }: scheduleType) => {
        if (repeatedDates.length > 0) {
          return repeatedDates.forEach((repeatDate) => {
            eventData.push({
              scheduleId: scheduleId as number,
              deviceId: deviceId as string,
              robotCommand,
              title: `${findRobotByDeviceId(deviceId)?.name} - ${
                mapCommandType[robotCommand.command]
              }`,
              start: `${repeatDate} ${startTime}`,
            });
          });
        }
        eventData.push({
          scheduleId: scheduleId as number,
          deviceId: deviceId as string,
          robotCommand: robotCommand,
          title: `${findRobotByDeviceId(deviceId)?.name} - ${
            mapCommandType[robotCommand.command]
          }`,
          start: `${startDate} ${startTime}`,
        });
      },
    );
    setEventList(eventData);
  }, [siteScheduleData, robotData]);

  const handleEventClick = (e: EventClickArg) => {
    const { scheduleId, deviceId, robotCommand } = e.event._def.extendedProps;
    // fetchSiteRobots();
    updateScheduleId(scheduleId);
    updateDeviceId(deviceId);
    updateRobotCommand(robotCommand);

    const allEventEls = document.querySelectorAll('.fc-event');
    allEventEls.forEach((eventEl) => {
      eventEl.classList.remove('fc-event-selected');
    });

    e.el.classList.add('fc-event-selected');
  };

  const handleDateClick = (e: DateClickArg) => {
    e.dayEl.classList.add('fc-day-today');

    const parentEl = e.dayEl.closest('.fc-daygrid-day');

    const allDayEls = document.querySelectorAll('.fc-daygrid-day');
    allDayEls.forEach((dayEl) => {
      dayEl.classList.remove('fc-day-today');
    });

    parentEl?.classList.add('fc-day-today');

    updateWorkStartDate(e.dateStr);
  };

  return (
    <section
      className={
        'w-[65%] min-w-[500px] h-[700px] p-7 rounded-md mt-5 bg-white shadow-sm'
      }
    >
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        locale={i18next.resolvedLanguage}
        initialView={'dayGridMonth'}
        headerToolbar={{
          start: 'prevYear,prev,next,nextYear today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        eventContent={renderEventContent}
        dayMaxEventRows={1}
        dayMaxEvents={true}
        height={'100%'}
        events={eventList}
        eventClick={(e: EventClickArg) => {
          handleEventClick(e);
        }}
        dateClick={(e: DateClickArg) => {
          handleDateClick(e);
        }}
      />
    </section>
  );
};

export default SettingCalendar;
