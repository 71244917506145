import { useEffect, useState } from 'react';

import {
  AuthRobotType,
  FirestoreCollenctions,
  RobotType,
  siteRobotType,
} from '../_types';
import { AuthType, Permission } from '../_types/user';
import useUserStore from '../store/userStore';
import { getSiteRobots } from '../_api/rest/robot';
import { doc, DocumentData, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export default function useRestRobotData(userData: AuthType | null) {
  const { userStatus, selectedUserAuthSite } = useUserStore();
  const [robotData, setRobotData] = useState<siteRobotType[]>([]);
  const [robotFilterData, setRobotFilterData] = useState<siteRobotType[]>([]);
  const [filterRobotType, setFilterRobotType] = useState<string>('');
  const [filterRobotName, setFilterRobotName] = useState<string>('');

  const [authRobots, setAuthRobots] = useState<AuthRobotType[]>([]);

  const fetchSiteRobots = async () => {
    if (!userData || !selectedUserAuthSite.length) return;

    if (userStatus === Permission.SUPER_ADMIN) {
      const robotData = await getSiteRobots(selectedUserAuthSite.join(','));
      setRobotData(robotData);
      return;
    }

    if (userData.permission !== 0) {
      const robots: AuthRobotType[] = [];

      const resList: DocumentData | undefined[] = [];

      // robot list
      await Promise.all(
        userData.auth_robots.map(async (currentRobot) => {
          const docRef = doc(
            db,
            FirestoreCollenctions.ROBOTS,
            currentRobot as any,
          );
          const res = await getDoc(docRef);
          resList.push(res.data());
          robots.push({ deviceId: res.data()?.device_id as string });
        }),
      );

      setAuthRobots(robots);
      console.log('robots = ', robots);
    }

    if (userStatus === Permission.ENGINEER || userStatus === Permission.USER) {
      const robotData = await getSiteRobots(selectedUserAuthSite.join(','));
      setRobotData(robotData);
      return;
    }
  };

  const selectedSiteRobotData = async (siteId: string) => {
    const robotData = await getSiteRobots(siteId);
    setRobotData(robotData);
  };

  const findRobotByDeviceId = (deviceId: string) => {
    return robotData?.find((item) => item.deviceId === deviceId);
  };

  useEffect(() => {
    if (!userData) return;
    fetchSiteRobots();
  }, [userData, userStatus, selectedUserAuthSite]);

  const authRobotFilter = (
    robotData: siteRobotType[],
    authRobots: AuthRobotType[],
  ) => {
    const matchingRobots: siteRobotType[] = robotData.filter(
      (robot: siteRobotType) =>
        authRobots.some(
          (item: AuthRobotType) => item.deviceId === robot.deviceId,
        ),
    );

    return matchingRobots;
  };

  useEffect(() => {
    if (!userData || !selectedUserAuthSite.length) return;
    if (userData.permission !== 0) {
      const AuthRobots = authRobotFilter(robotData, authRobots);
      setRobotFilterData(AuthRobots);
    } else {
      setRobotFilterData(robotData);
    }

    setFilterRobotType('전체');
  }, [robotData]);

  useEffect(() => {
    if (!userData || !selectedUserAuthSite.length) return;

    const filterData = robotData?.filter(
      (item) =>
        (!filterRobotType ||
          filterRobotType === '전체' ||
          item.modelType === filterRobotType) &&
        (!filterRobotName ||
          item.name.toLowerCase().includes(filterRobotName.toLowerCase())),
    );

    if (userData.permission !== 0) {
      const AuthRobots = authRobotFilter(robotData, authRobots);
      setRobotFilterData(AuthRobots);
    } else {
      setRobotFilterData(filterData);
    }
  }, [robotData, filterRobotType, filterRobotName]);

  return {
    fetchSiteRobots,
    robotData,
    robotFilterData,
    selectedSiteRobotData,
    findRobotByDeviceId,
    filterRobotType,
    filterRobotName,
    setRobotData,
    setFilterRobotType,
    setFilterRobotName,
  };
}
