export const getRobotStateColor = (color: string) => {
  switch (color) {
    case 'RED':
      return 'text-red-500';
    case 'ORANGE':
      return 'text-orange-400';
    case 'GREEN':
      return 'text-emerald-500';
    case 'GRAY':
      return 'text-gray-400';
    default:
      return 'text-transparent';
  }
};
