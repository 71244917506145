import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import useCanvas, { RobotInfo } from '../../../hooks/useCanvas';
import MapControls from './MapControls';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { t } from 'i18next';

interface Props {
  imageSrc: string;
  robotInfo: RobotInfo[];
  hasControls: boolean;
  multiple?: number;
}

const MapCard = forwardRef<HTMLCanvasElement, Props>(
  ({ imageSrc, robotInfo, hasControls, multiple = 1 }, ref) => {
    const WIDHT = 580;
    const HEIGHT = 300;

    const { canvasRef } = useCanvas({
      robotInfo,
      imageSrc: imageSrc || '',
      multiple,
      speed: 15,
    });

    useImperativeHandle(ref, () => canvasRef.current!);

    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext('2d');
      const image = new Image();

      image.src = imageSrc;
      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        // 이미지가 로드되면, 캔버스 크기에 맞게 이미지를 그림
        ctx?.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
        ctx?.drawImage(image, 0, 0, canvas?.width || 0, canvas?.height || 0); // 캔버스를 꽉 채우는 이미지
      };
    }, [imageSrc]);

    return (
      <div className="relative w-full bg-white">
        <div className="flex flex-col gap-[12px]  h-full">
          {imageSrc ? (
            <TransformWrapper>
              <div
                className="relative flex-grow w-full overflow-hidden border-2 rounded-sm border-secondary"
                id={'test'}
              >
                <TransformComponent
                  wrapperStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <canvas
                    id={'map'}
                    ref={canvasRef}
                    width={WIDHT * multiple}
                    height={HEIGHT * multiple}
                    style={{ minHeight: HEIGHT }}
                    className={`block mx-auto max-w-full  object-contain`}
                  />
                </TransformComponent>
              </div>
              {hasControls && <MapControls />}
            </TransformWrapper>
          ) : (
            <div
              style={
                multiple === 1
                  ? { minHeight: HEIGHT }
                  : { minHeight: '31.25rem' }
              }
              className={`text-[${0.8 * multiple}rem]  h-full flex justify-center items-center w-full bg-black text-white opacity-70 `}
            >
              {t('No_map_image_available')}
            </div>
          )}
        </div>
      </div>
    );
  },
);

MapCard.displayName = 'MapCard';

export default MapCard;
