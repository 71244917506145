import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export interface TextLabelProps {
  date?: string;
  fontSize: number;
  dateFormat: string;
  useNow?: boolean;
  fontColor?: string;
  useClock: boolean;
}

export const DateLabel = ({
  date,
  dateFormat,
  useNow = true,
  fontColor,
  fontSize,
  useClock = false,
}: TextLabelProps) => {
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (useClock && useNow) {
      interval = setInterval(() => {
        setCurrentTime(dayjs());
      }, 1000);
    } else {
      setCurrentTime(useNow ? dayjs() : dayjs(date));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [useClock, useNow, date]);

  return (
    <div style={{ fontSize, color: fontColor }}>
      {currentTime.format(dateFormat)}
    </div>
  );
};
