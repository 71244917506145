import useAuth from '../hooks/useAuth';
import InquiryModal from '../components/modal/InquiryModal';
import { Suspense, useEffect, useRef, useState } from 'react';
import SearchInput from '../components/common/SearchInput';
import { t } from 'i18next';
import DailyWorkDistributionChart from '../components/sollinkHome/widget/DailyWorkDistributionChart';
import DailyOperatingRobotsChart from '../components/sollinkHome/widget/DailyOperatingRobotsChart';
import DailyErrorLogGrid from '../components/sollinkHome/widget/DailyErrorLogGrid';
import ActionRequiredRobotsGrid from '../components/sollinkHome/widget/ActionRequiredRobotsGrid';
import ProgressBar from '../components/sollinkHome/widget/ProgressBar';
import { Button, Spin } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import { GrDocumentDownload } from 'react-icons/gr';
import { LoadingOutlined } from '@ant-design/icons';
import { ErrorBoundary } from 'react-error-boundary';
import MapContainer from '../components/sollinkHome/widget/MapContainer';

const SollinkHomePage = () => {
  const { hasAuthSite, hasAuthRobot, login } = useAuth();
  const [selectedSite, setSelectedSite] = useState<string>('');

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [canvasDataUrl, setCanvasDataUrl] = useState<string | null>(null);
  const captureCanvasAsImage = () => {
    if (canvasRef.current) {
      const dataUrl = canvasRef.current.toDataURL('image/png');
      setCanvasDataUrl(dataUrl);
    }
  };

  useEffect(() => {
    captureCanvasAsImage();
  }, [canvasRef.current]);
  const pdfDownload = async () => {
    const element = document.querySelector('#pdfDownload') as HTMLElement;
    if (!element) return;

    const canvas = await html2canvas(element, { scale: 1.5 });
    const imageFile = canvas.toDataURL('image/png');
    const doc = new jsPDF('l', 'px', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const customHeight = canvas.height * 0.21;

    doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight);
    doc.save('SOLlink_HOME_' + dayjs().format('YYYY-MM-DD') + '.pdf');

    // const canvas = await html2canvas(document.querySelector('#pdfDownload')!, {
    //   scale: 1.5,
    //   useCORS: true,
    // });
    // const imageFile = canvas.toDataURL('image/png');
    //
    // console.log('imageFile = ', imageFile);
    //
    // // 결과를 이미지로 화면에 추가하거나 다운로드
    // const img = new Image();
    // img.src = imageFile;
    // document.body.appendChild(img); // 예시: 캡처 결과 이미지 화면에 추가
    //
    // const doc = new jsPDF('p', 'px', 'a4');
    //
    // const pageWidth = doc.internal.pageSize.getWidth();
    // const pageHeight = doc.internal.pageSize.getHeight();
    //
    // //이미지의 길이와 pdf의 가로길이가 다르므로 이미지 길이를 기준으로 비율을 구함
    // const widthRatio = pageWidth / canvas.width;
    // //비율에 따른 이미지 높이
    // const customHeight = canvas.height * 0.23;
    //
    // //pdf에 1장에 대한 이미지 추가
    // doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight);
    // //doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
    // //감소하면서 남은 길이 변수
    // let heightLeft = customHeight;
    // //증가하면서 이미지 자를 위치 변수
    // let heightAdd = -pageHeight;
    //
    // // 한 페이지 이상일 경우
    // while (heightLeft >= pageHeight) {
    //   //pdf페이지 추가
    //   doc.addPage();
    //   //남은 이미지를 추가
    //   doc.addImage(imageFile, 'png', 0, heightAdd, pageWidth, customHeight);
    //   //남은길이
    //   heightLeft -= pageHeight;
    //   //남은높이
    //   heightAdd -= pageHeight;
    // }
    // //문서저장
    // doc.save('SOLlink_HOME_' + dayjs().format('YYYY-MM-DD') + '.pdf');
  };

  return (
    <div className="w-[100%] pl-[75px] bg-neutral-50">
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t('Sollink_Home')}
      </div>
      <section
        className="pt-[90px] px-12 items-center justify-between pb-3"
        data-name="bg"
      >
        <div className={'mb-3 mr-[16px]'}>
          <SearchInput handleChangeSite={setSelectedSite} />
          <Button
            className={
              'float-end buttonSwitch flex items-center justify-center'
            }
            onClick={pdfDownload}
            type={'primary'}
            icon={<GrDocumentDownload />}
          >
            PDF
          </Button>
        </div>

        <div id={'pdfDownload'}>
          <section className="flex flex-col w-full gap-4 mb-4 tab:flex-row">
            <div className="w-full tab:w-2/3 bg-white rounded-md  min-h-[500px] flex flex-col">
              <div className="relative w-full h-full min-h-[500px]">
                <ErrorBoundary
                  fallback={<div className="p-4">에러 컴포넌트 </div>}
                >
                  <Suspense
                    fallback={
                      <div className="flex items-center justify-center w-full h-full min-h-[500px]">
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="large"
                        />
                      </div>
                    }
                  >
                    <MapContainer selectedSite={selectedSite} ref={canvasRef} />
                  </Suspense>
                </ErrorBoundary>
              </div>
            </div>
            <div className="flex flex-col w-full tab:w-1/3 gap-4 min-h-[500px]">
              <div className="flex-1 bg-white rounded-md ">
                <ProgressBar />
              </div>
              <div className="flex-1 bg-white rounded-md ">
                <ActionRequiredRobotsGrid />
              </div>
            </div>
          </section>

          <section className="mb-4">
            <div className="bg-white rounded-md  h-[400px]">
              <DailyWorkDistributionChart />
            </div>
          </section>
          <section className="flex flex-col gap-4 tab:flex-row">
            <div className="w-full tab:w-1/2 bg-white rounded-md h-[400px]">
              <DailyOperatingRobotsChart />
            </div>
            <div className="w-full tab:w-1/2 bg-white rounded-md  h-[400px]">
              <DailyErrorLogGrid />
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};
export default SollinkHomePage;
