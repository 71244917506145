import React from 'react';
import '../../../../stories/widget/chart/columnChart/columnChart.css';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

export interface columnChartProps {
  options: ApexOptions;
  series: {
    data: number[];
  }[];

  height?: number | string;
  width?: number | string;
}

export const ColumnChart = ({
  options,
  series,
  height = '100%',
  width,
}: columnChartProps) => {
  return (
    <div id="chart" style={{ height: height }}>
      <ReactApexChart
        type={'bar'}
        options={options}
        series={series}
        height={height}
        width={width}
      />
    </div>
  );
};
