import React, { useEffect, useState } from 'react';
import { Select, Input, Button } from 'antd';
import '../../style/workflow.css';
import { useTranslation } from 'react-i18next';
import { onlyNumbers } from '../../util/commonUtil';

interface PropsTypes {
  type: string;
  options: any;
  id: string | undefined;
  placeholder: string;
  size: any;
  saveValue: string;
  onInputData: (data: string) => void;
  style: object;
}

const SelectWithInput = ({
  options,
  id,
  size,
  placeholder,
  saveValue,
  onInputData,
  style,
  type,
}: PropsTypes) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectValue, setSelectValue] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false); // 드롭다운 상태 관리

  const { t } = useTranslation();

  useEffect(() => {
    onInputData(selectValue);
  }, [selectValue]);

  useEffect(() => {
    setSelectValue(saveValue);
  }, [saveValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (type === 'number') {
      value = onlyNumbers(value);
    }
    setInputValue(value);
  };

  const handleSelectChange = (value: string) => {
    setSelectValue(value);
  };

  const handleButtonClick = () => {
    setSelectValue(inputValue);
    setInputValue('');
    setDropdownOpen(false); // 입력 버튼 클릭 시 드롭다운 닫기
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    // 입력 버튼 클릭 시에는 드롭다운이 닫히게 하고, 그 외에는 사용자가 제어 가능
    setDropdownOpen(open);
  };

  return (
    <Select
      id={id}
      className={'nodrag'}
      suffixIcon={null}
      size={size}
      style={style}
      placeholder={placeholder}
      options={options}
      value={selectValue === '' || !selectValue ? null : selectValue}
      onChange={handleSelectChange}
      open={dropdownOpen} // 드롭다운 상태 관리
      onDropdownVisibleChange={handleDropdownVisibleChange} // 드롭다운 상태 변경 이벤트
      dropdownRender={(menu) => (
        <>
          {menu}
          <div style={{ display: 'flex', padding: 8 }}>
            <Input
              value={inputValue}
              className={'nodrag'}
              onChange={handleInputChange}
              style={{
                flex: 'auto',
                width: 60,
                marginRight: '1px',
              }}
            />
            <Button
              onClick={handleButtonClick} // 버튼 클릭 시 드롭다운 닫기
              style={{ width: 40, padding: '0px' }}
            >
              {t('input')}
            </Button>
          </div>
        </>
      )}
    />
  );
};

export default SelectWithInput;
