import React, { useEffect, useState } from 'react';
import { Button, Drawer, Input, Radio, RadioChangeEvent, Select } from 'antd';
import { RiAddLine } from 'react-icons/ri';
import { FiMinus } from 'react-icons/fi';
import { FaSave } from 'react-icons/fa';
import TextArea from 'antd/es/input/TextArea';
import useWorkflowStore from '../../store/workflowStore';
import { toast } from 'react-toastify';
import { onlyNumbers } from '../../util/commonUtil';
import { useTranslation } from 'react-i18next';
import { getAlarmCategoryList } from '../../_api/rest/workflow';
import errorPage from '../../pages/ErrorPage';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import _ from 'lodash';

const settingSidebar = (props: any) => {
  const { variableParamList } = useWorkflowStore();

  const { t, i18n } = useTranslation();

  const [selectNode, setSelectNode] = useState<any>(null);
  const [selectEdge, setSelectEdge] = useState<any>(null);

  const [title, setTitle] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [action, setAction] = useState<string>('');

  const [inputChangeValue, setInputChangeValue] = useState<any>({
    groupId: 1,
    carId: 1,
    customValue: '',
    appControl: '',
  });

  const [commonValue, setCommonValue] = useState<any>({
    timeout: '100',
    alias: '',
    estimatedTime: '300',
  });

  const [conditionName, setConditionName] = useState<string>('');

  const [conditionList, setConditionList] = useState<any>([
    { autoCondition: 'select', name: '', operator: 'select', value: '' },
  ]);
  const [outputConditionList, setOutputConditionList] = useState<any>([
    { name: '', value: '' },
  ]);

  const [customKeyList, setCustomKeyList] = useState<string[]>(['']);

  const [conditionType, setConditionType] = useState<string>('simple');

  const [mapData, setMapData] = useState<any>({
    mapId: '',
    x: '',
    y: '',
    z: '',
  });

  const [drawerWidth, setDrawerWidth] = useState<number>(378);

  const oper = [
    { label: t('select'), value: 'select' },
    { label: '==', value: '==' },
    { label: '!=', value: '!=' },
    { label: '<=', value: '<=' },
    { label: '>=', value: '>=' },
    { label: '>', value: '>' },
    { label: '<', value: '<' },
  ];

  const autoValue = [
    { label: '조건선택', value: 'select' },
    { label: '이동완료', value: 'moveComplete' },
    { label: '입력완료', value: 'inputComplete' },
  ];

  const [variableList, setVariableList] = useState<any>([]);

  //알람 노드 - 종류에 따른 파라미터
  //알람노드 알람종류
  const [alarmCategoryList, setAlarmCategoryList] = useState<
    (DefaultOptionType | BaseOptionType)[]
  >([]);
  const [selectAlarmCategory, setSelectAlarmCategory] = useState<string | null>(
    null,
  );
  const [selectAlarmCategoryData, setSelectAlarmCategoryData] =
    useState<any>(null);

  useEffect(() => {
    if (props.open) {
      const action = props.nodeData.data.label.props.action;
      if (action === 'NOTICE_ALARM') {
        (async () => {
          await getAlarmCategoryAPI();
        })();
      }
    }
  }, [props.open]);

  const getAlarmCategoryAPI = async () => {
    await getAlarmCategoryList()
      .then((res: any) => {
        if (res !== null && res !== undefined) {
          if (res.length > 0) {
            res.forEach((item: any, i: number) => {
              item['value'] = item.name;
              item['label'] = item.nameKr;
            });
          }
          setAlarmCategoryList(res);
        }
      })
      .catch((error: any) => {
        errorPage();
      });
  };

  const onSelectAlarmCategory = (value: string) => {
    const selectAlarm = alarmCategoryList.find((data) => data.name === value);

    console.log('alarmCategoryList = ', alarmCategoryList);

    setSelectAlarmCategory(value);
    setSelectAlarmCategoryData(selectAlarm);
  };

  const onInputAlarmChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    const value = e.target.value;

    // selectAlarmCategoryData 복사본을 생성
    const updatedData = { ...selectAlarmCategoryData };

    // tplParams 배열에서 key 값과 일치하는 항목의 value 업데이트
    updatedData.tplParams = updatedData.tplParams.map((item: any) => {
      if (item.key.name === key) {
        return { ...item, value }; // 새로운 value로 업데이트된 item 반환
      }
      return item; // 수정이 없을 경우 원래 item 반환
    });

    console.log('updateData = ', updatedData);

    // 상태 업데이트
    setSelectAlarmCategoryData(updatedData);
  };

  useEffect(() => {
    if (Object.keys(variableParamList).length > 0) {
      let list: any[] = [];
      const copyData = _.cloneDeep(variableParamList.userParam);

      if (
        variableParamList.userParam &&
        variableParamList.userParam.length > 0
      ) {
        variableParamList.userParam.forEach((item: string, i: number) => {
          if (item && item !== '') {
            list.push({
              id: item,
              label: item,
              name: item,
              value: item,
            });
          }
        });
      }

      setVariableList(list);
    }
  }, [variableParamList.userParam]);

  useEffect(() => {
    setSelectEdge(props.edgeData);
  }, [props.edgeData]);

  useEffect(() => {
    setSelectNode(props.nodeData);

    (async () => {
      await dataLoad();
    })();
  }, [props.open]);

  const dataReset = () => {
    setConditionList([
      { autoCondition: 'select', name: '', operator: 'select', value: '' },
    ]);
    setCustomKeyList(['']);
    setInputChangeValue({
      groupId: 1,
      carId: 1,
      customValue: '',
    });
    setConditionName('');
    setCommonValue({
      alias: '',
      timeout: '100',
      estimatedTime: '300',
    });
    setMapData({
      map: '',
      x: '',
      y: '',
      z: '',
    });
    setAlarmCategoryList([]);
    setSelectAlarmCategory(null);
    setSelectAlarmCategoryData([]);
  };

  const dataLoad = () => {
    if (props.nodeData !== null && props.nodeData !== undefined) {
      dataReset();

      const action = props.nodeData.data.label.props.action;

      switch (action) {
        case 'CHECK': {
          if (
            props.nodeData.condition !== null &&
            props.nodeData.condition !== undefined &&
            Object.keys(props.nodeData.condition).length !== 0
          ) {
            if (props.nodeData.condition.conditionType !== null) {
              setConditionType(props.nodeData.condition.conditionType);

              setConditionName(props.nodeData.condition.conditionName);

              setCommonValue({
                alias: props.nodeData.condition.commonValue?.alias,
                timeout: props.nodeData.condition.commonValue?.timeout,
                estimatedTime:
                  props.nodeData.condition.commonValue?.estimatedTime,
              });

              if (props.nodeData.condition.conditionType === 'simple') {
                setConditionList([...props.nodeData.condition.conditionList]);
                setInputChangeValue({
                  groupId: props.nodeData.condition.inputValue?.groupId,
                  carId: props.nodeData.condition.inputValue?.carId,
                  customValue: '',
                });
              } else {
                setCustomKeyList([...props.nodeData.condition.conditionList]);
                setInputChangeValue({
                  groupId: props.nodeData.condition.inputValue?.groupId,
                  carId: props.nodeData.condition.inputValue?.carId,
                  customValue: props.nodeData.condition.inputValue?.customValue,
                });
              }
            }
          } else {
            setConditionType('simple');
            setConditionName('');
            setConditionList([
              {
                autoCondition: 'select',
                name: '',
                operator: 'select',
                value: '',
              },
            ]);
            setCustomKeyList(['']);
            setInputChangeValue({
              groupId: 1,
              carId: 1,
              customValue: '',
            });
            setCommonValue({
              alias: '',
              timeout: '100',
              estimatedTime: '300',
            });
          }

          break;
        }

        case 'USER_SELECT':
        case 'CHECK_SELECT': {
          if (
            props.nodeData.conditionCommon !== null &&
            props.nodeData.conditionCommon !== undefined
          ) {
            setCommonValue({
              alias: props.nodeData.conditionCommon.alias,
              timeout: props.nodeData.conditionCommon.timeout,
              estimatedTime: props.nodeData.conditionCommon.estimatedTime,
            });
          } else {
            setCommonValue({
              alias: '',
              timeout: '100',
              estimatedTime: '300',
            });
          }

          if (props.edgeData !== null && props.edgeData !== undefined) {
            const edgeId = props.edgeData.id;

            if (
              props.nodeData.condition !== null &&
              props.nodeData.condition !== undefined
            ) {
              const conditions = props.nodeData.condition;

              const findEdge = conditions.filter(
                (data: any) => data.edgeId === edgeId,
              );

              if (findEdge.length > 0) {
                setConditionName(findEdge[0].conditionName);

                setConditionType(findEdge[0].conditionType);

                if (findEdge[0].conditionType === 'simple') {
                  setConditionList([...findEdge[0].conditionList]);
                  setInputChangeValue({
                    groupId: findEdge[0].inputValue.groupId,
                    carId: findEdge[0].inputValue.carId,
                    customValue: '',
                  });
                } else {
                  setCustomKeyList([...findEdge[0].conditionList]);
                  setInputChangeValue({
                    groupId: findEdge[0].inputValue.groupId,
                    carId: findEdge[0].inputValue.carId,
                    customValue: findEdge[0].inputValue.customValue,
                  });
                }
              } else {
                setConditionList([
                  {
                    autoCondition: 'select',
                    name: '',
                    operator: 'select',
                    value: '',
                  },
                ]);
                setCustomKeyList(['']);
                setInputChangeValue({
                  groupId: 1,
                  carId: 1,
                  customValue: '',
                });
                setConditionName('');
                setConditionType('simple');
              }
            }
          }

          break;
        }

        case 'CHANGE_MAP': {
          if (
            props.nodeData.condition !== null &&
            props.nodeData.condition !== undefined
          ) {
            setCommonValue({
              alias: props.nodeData.condition.commonValue.alias,
              estimatedTime: props.nodeData.condition.commonValue.estimatedTime,
            });
            setMapData({
              mapId: props.nodeData.condition.mapValue.mapId,
              x: props.nodeData.condition.mapValue.x,
              y: props.nodeData.condition.mapValue.y,
              z: props.nodeData.condition.mapValue.z,
            });
          } else {
            setCommonValue({
              alias: '',
              estimatedTime: '300',
            });
            setMapData({
              mapId: '',
              x: '',
              y: '',
              z: '',
            });
          }

          break;
        }

        case 'KEEP_OPEN':
        case 'OPEN_DOOR':
        case 'HALL_CALL':
        case 'CAR_CALL': {
          if (
            props.nodeData.condition !== null &&
            props.nodeData.condition !== undefined
          ) {
            setCommonValue({
              alias: props.nodeData.condition.commonValue.alias,
              estimatedTime: props.nodeData.condition.commonValue.estimatedTime,
            });
            setInputChangeValue({
              groupId: props.nodeData.condition.inputValue.groupId,
              carId: props.nodeData.condition.inputValue.carId,
              customValue: '',
            });
          } else {
            setCommonValue({
              alias: '',
              estimatedTime: '300',
            });
            setInputChangeValue({
              groupId: 1,
              carId: 1,
              customValue: '',
            });
          }

          break;
        }

        case 'CONTROL_DRAWER':
        case 'STOP':
        case 'APP_STOP':
        case 'ON_TIME':
        case 'WAIT':
        case 'AUTO_DOOR_OPEN':
        case 'AUTO_DOOR_CLOSE':
        case 'BACK_TO_SCHEDULE':
        case 'HOME':
        case 'CHARGE':
        case 'MOVE':
        case 'CHANGE_FLOOR':
        case 'CLEAN': {
          if (
            props.nodeData.condition !== null &&
            props.nodeData.condition !== undefined
          ) {
            setCommonValue({
              alias: props.nodeData.condition.commonValue.alias,
              estimatedTime: props.nodeData.condition.commonValue.estimatedTime,
            });
          } else {
            setCommonValue({
              alias: '',
              estimatedTime: '300',
            });
          }

          break;
        }

        case 'APP_CONTROL': {
          if (
            props.nodeData.condition !== null &&
            props.nodeData.condition !== undefined
          ) {
            setCommonValue({
              alias: props.nodeData.condition.commonValue.alias,
              estimatedTime: props.nodeData.condition.commonValue.estimatedTime,
            });
            setInputChangeValue({
              groupId: 1,
              carId: 1,
              customValue: '',
              appControl: JSON.stringify(
                props.nodeData.condition.inputValue.appControl,
              ),
            });
          } else {
            setCommonValue({
              alias: '',
              estimatedTime: '300',
            });
            setInputChangeValue({
              groupId: 1,
              carId: 1,
              customValue: '',
              appControl: '',
            });
          }

          break;
        }

        case 'OUT_CONTROL': {
          if (
            props.nodeData.condition !== null &&
            props.nodeData.condition !== undefined
          ) {
            setCommonValue({
              alias: props.nodeData.condition.commonValue.alias,
              estimatedTime: props.nodeData.condition.commonValue.estimatedTime,
            });
            setOutputConditionList(props.nodeData.condition.conditionList);
          } else {
            setCommonValue({
              alias: '',
              estimatedTime: '300',
            });
            setOutputConditionList([{ name: '', value: '' }]);
          }

          break;
        }
        case 'NOTICE_ALARM': {
          console.log('props = ', props.nodeData);

          if (
            props.nodeData.condition !== null &&
            props.nodeData.condition !== undefined
          ) {
            setCommonValue({
              alias: props.nodeData.condition.commonValue.alias,
              estimatedTime: props.nodeData.condition.commonValue.estimatedTime,
            });
            setAlarmCategoryList(props.nodeData.condition.alarmData);

            const alarmList = props.nodeData.condition.alarmData;
            const name = props.nodeData.condition.alarmData.name;

            setSelectAlarmCategory(name);
            setSelectAlarmCategoryData(alarmList);
          } else {
            setCommonValue({
              alias: '',
              estimatedTime: '300',
            });
            setAlarmCategoryList([]);
          }

          break;
        }

        default: {
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (props.edgeData) {
      setSelectEdge(props.edgeData);
    }
  }, [props.edgeData]);

  useEffect(() => {
    if (selectNode !== undefined && selectNode !== null) {
      setTitle(selectNode.data.label.props.title);
      setFullName(selectNode.data.label.props.fullName);
      setAction(selectNode.data.label.props.action);
    }
  }, [selectNode]);

  const onSettingSidebarClose = () => {
    // console.log('props.taskId = ', props.taskId);
    //
    // if (!props.taskId) {
    //   setOutputConditionList([{ name: '', value: '' }]);
    //   setConditionList([{ name: '', operator: '', value: '' }]);
    //   setCustomKeyList(['']);
    //   setInputChangeValue({
    //     groupId: 1,
    //     carId: 1,
    //     alias: '',
    //     timeout: 100,
    //     customValue: '',
    //   });
    //   setConditionName('');
    // } else {
    //   dataLoad();
    // }

    props.onNodeDoubleClick(false);
  };

  const conditionDel = (idx: number) => {
    conditionList.splice(idx, 1);
    setConditionList([...conditionList]);
  };

  const customKeyDel = (idx: number) => {
    customKeyList.splice(idx, 1);
    setCustomKeyList([...customKeyList]);
  };

  const outputConditionDel = (idx: number) => {
    outputConditionList.splice(idx, 1);
    setOutputConditionList([...outputConditionList]);
  };

  const outputConditionAdd = () => {
    const data = { name: '', value: '' };

    if (outputConditionList.length < 10) {
      setOutputConditionList([...outputConditionList, data]);
    }
  };

  const conditionAdd = () => {
    const data = { name: '', operator: '', value: '' };

    if (conditionList.length < 10) {
      setConditionList([...conditionList, data]);
    }
  };

  const customKeyAdd = () => {
    const data = '';

    if (customKeyList.length < 10) {
      setCustomKeyList([...customKeyList, data]);
    }
  };

  const inputName = (e: any, idx: number) => {
    const val = e.target.value;

    const newList = conditionList.map((item: any, index: number) => {
      if (index === idx) {
        return { ...item, name: val };
      }
      return item;
    });
    setConditionList(newList);
  };

  const selectOperator = (value: any, idx: number) => {
    const newList = conditionList.map((item: any, index: number) => {
      if (index === idx) {
        return { ...item, operator: value };
      }
      return item;
    });
    setConditionList(newList);
  };

  const selectName = (value: any, idx: number) => {
    const newList = conditionList.map((item: any, index: number) => {
      if (index === idx) {
        return { ...item, name: value };
      }
      return item;
    });
    setConditionList(newList);
  };

  const selectAutoCondition = (value: any, idx: number) => {
    const newList = conditionList.map((item: any, index: number) => {
      if (index === idx) {
        if (value === 'moveComplete') {
          return {
            ...item,
            autoCondition: value,
            name: 'mainState',
            operator: '==',
            value: 'STANDBY',
          };
        } else if (value === 'inputComplete') {
          return {
            ...item,
            autoCondition: value,
            name: 'appDeviceData:userInteractionStatus',
            operator: '==',
            value: 'COMPLETE',
          };
        } else {
          return {
            ...item,
            autoCondition: 'select',
            name: '',
            operator: 'select',
            value: '',
          };
        }
      }
      return item;
    });
    setConditionList(newList);
  };

  const inputValue = (e: any, idx: number) => {
    const val = e.target.value;

    const newList = conditionList.map((item: any, index: number) => {
      if (index === idx) {
        return { ...item, value: val };
      }
      return item;
    });
    setConditionList(newList);
  };

  ///////////////////////////////////
  const outputInputName = (e: any, idx: number) => {
    const val = e.target.value;

    const newList = outputConditionList.map((item: any, index: number) => {
      if (index === idx) {
        return { ...item, name: val };
      }
      return item;
    });

    setOutputConditionList(newList);
  };

  const outputInputValue = (e: any, idx: number) => {
    const val = e.target.value;

    const newList = outputConditionList.map((item: any, index: number) => {
      if (index === idx) {
        return { ...item, value: val };
      }
      return item;
    });

    setOutputConditionList(newList);
  };

  ////////////////////////

  const inputCustomKey = (e: any, idx: number) => {
    const val = e.target.value;

    const newList = customKeyList.map((item: any, index: number) => {
      if (index === idx) {
        return val;
      }
      return item;
    });

    setCustomKeyList(newList);
  };

  const selectCustomKey = (value: string, idx: number) => {
    console.log(value, idx);

    const newList = customKeyList.map((item: any, index: number) => {
      if (index === idx) {
        return value;
      }
      return item;
    });

    setCustomKeyList(newList);
  };

  const onChange = (e: RadioChangeEvent) => {
    const val = e.target.value;

    if (val === 'simple') {
      setCustomKeyList(['']);
      setInputChangeValue({
        ...inputChangeValue,
        customValue: '',
      });
    } else {
      setConditionList([{ name: '', operator: 'select', value: '' }]);
    }

    setConditionType(e.target.value);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'alias' || name === 'timeout' || name === 'estimatedTime') {
      setCommonValue({
        ...commonValue,
        [name]: name === 'alias' ? value : onlyNumbers(value),
      });
    } else if (
      name === 'mapId' ||
      name === 'x' ||
      name === 'y' ||
      name === 'z'
    ) {
      setMapData({
        ...mapData,
        [name]: value,
      });
    } else {
      setInputChangeValue({
        ...inputChangeValue,
        [name]: value,
      });
    }
  };

  const onInputName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConditionName(e.target.value);
  };

  const onInputCustomValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInputChangeValue({
      ...inputChangeValue,
      [name]: value,
    });
  };

  const settingDiv = () => {
    if (props.nodeData) {
      const action = props.nodeData.data.label.props.action;
      const type = props.nodeData.data.label.props.type;

      let html;

      switch (action) {
        case 'OUT_CONTROL': {
          html = (
            <div>
              <div className={'mt-5 w-[290px]'}>
                <div className={'float-right'}>
                  <Button
                    size={'small'}
                    onClick={() => outputConditionAdd()}
                    className={'h-[30px] float-end'}
                  >
                    <RiAddLine size={15} style={{ alignItems: 'center' }} />
                  </Button>
                </div>

                <div>
                  {/*10개*/}
                  {outputConditionList.map((data: any, idx: number) => {
                    return (
                      <div key={idx} style={{ marginTop: '10px' }}>
                        <Input
                          placeholder={t('variableName')}
                          size={'middle'}
                          style={{ width: 120, marginRight: 5 }}
                          onChange={(e) => outputInputName(e, idx)}
                          value={data.name}
                        />
                        <Input
                          placeholder={t('value')}
                          size={'middle'}
                          style={{ width: 120, marginRight: 5 }}
                          onChange={(e) => outputInputValue(e, idx)}
                          value={data.value}
                        />

                        {idx > 0 && (
                          <Button
                            size={'small'}
                            style={{ height: 30, float: 'inline-end' }}
                            onClick={() => outputConditionDel(idx)}
                          >
                            <FiMinus
                              size={15}
                              style={{ alignItems: 'center' }}
                            />
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={'border-b-[1px] mt-5'}></div>

              <div className={'mt-8 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('actionAlias')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterAlias')}
                  width={70}
                  name="alias"
                  value={commonValue.alias}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-8 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('estimatedTime')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterEstimatedTime')}
                  className={'w-[250px] mr-3'}
                  name="estimatedTime"
                  value={commonValue.estimatedTime}
                  onChange={onInputChange}
                />
                <span className={'mr-4 self-center right-0'}>sec</span>
              </div>
            </div>
          );
          break;
        }

        case 'USER_SELECT':
        case 'CHECK':
        case 'CHECK_SELECT': {
          html = (
            <div>
              {type === 'ELV' &&
                ((props.edgeData === null && action === 'CHECK') ||
                  (props.edgeData === null &&
                    (action === 'CHECK_SELECT' ||
                      action === 'USER_SELECT'))) && (
                  <>
                    <div className={'mt-4 flex'}>
                      <span className={'mr-4 w-[30%] self-center'}>
                        그룹 ID
                      </span>
                      <Input
                        type="text"
                        name="groupId"
                        value={inputChangeValue.groupId}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className={'mt-4 flex'}>
                      <span className={'mr-4 w-[30%] self-center'}>
                        카(CAR) ID
                      </span>
                      <Input
                        type="text"
                        name="carId"
                        value={inputChangeValue.carId}
                        onChange={onInputChange}
                      />
                    </div>

                    <div className={'border-b-[1px] mt-5 mb-8'}></div>
                  </>
                )}

              {((props.edgeData === null && action === 'CHECK') ||
                (props.edgeData !== null &&
                  (action === 'CHECK_SELECT' || action === 'USER_SELECT'))) && (
                <>
                  {action !== 'CHECK' && (
                    <div className={'mt-3 flex right-0'}>
                      <span className={'mr-4 w-[30%] self-center'}>
                        {t('conditionName')}
                      </span>
                      <Input
                        type="text"
                        placeholder={t('inputCondition')}
                        value={conditionName}
                        onChange={onInputName}
                      />
                    </div>
                  )}
                  <div
                    className={
                      props.edgeData === null &&
                      (action === 'CHECK_SELECT' || action === 'USER_SELECT')
                        ? 'mt-8'
                        : 'mt-3'
                    }
                  >
                    <span className={'mr-5'}>{t('conditionMethod')}</span>
                    <Radio.Group onChange={onChange} value={conditionType}>
                      <Radio value={'simple'}>Simple {t('condition')}</Radio>
                      <Radio value={'custom'}>Custom {t('condition')}</Radio>
                    </Radio.Group>
                  </div>

                  {conditionType === 'simple' && (
                    <div className={'mt-5 w-[400px]'}>
                      <div className={'float-right'}>
                        <Button
                          size={'small'}
                          onClick={() => conditionAdd()}
                          className={'h-[30px] float-end'}
                        >
                          <RiAddLine
                            size={15}
                            style={{ alignItems: 'center' }}
                          />
                        </Button>
                      </div>

                      <div>
                        {action === 'USER_SELECT' ? (
                          <>
                            {conditionList.map((data: any, idx: number) => {
                              return (
                                <div key={idx} style={{ marginTop: '10px' }}>
                                  <Select
                                    placeholder={t('variableName')}
                                    size={'middle'}
                                    style={{ width: 150, marginRight: 5 }}
                                    options={variableList}
                                    onChange={(value) => selectName(value, idx)}
                                    value={data.name}
                                  />

                                  <Select
                                    placeholder={t('operator')}
                                    size={'middle'}
                                    style={{ width: 70, marginRight: 5 }}
                                    options={oper}
                                    onChange={(value) =>
                                      selectOperator(value, idx)
                                    }
                                    value={data.operator}
                                  />
                                  <Input
                                    placeholder={t('value')}
                                    size={'middle'}
                                    style={{ width: 130, marginRight: 5 }}
                                    onChange={(e) => inputValue(e, idx)}
                                    value={data.value}
                                  />

                                  {idx > 0 && (
                                    <Button
                                      size={'small'}
                                      style={{
                                        height: 30,
                                        float: 'inline-end',
                                      }}
                                      onClick={() => conditionDel(idx)}
                                    >
                                      <FiMinus
                                        size={15}
                                        style={{ alignItems: 'center' }}
                                      />
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {conditionList.map((data: any, idx: number) => {
                              return (
                                <div key={idx} style={{ marginTop: '10px' }}>
                                  <Select
                                    placeholder={t('condition')}
                                    size={'middle'}
                                    style={{ width: 90, marginRight: 5 }}
                                    options={autoValue}
                                    onChange={(value) =>
                                      selectAutoCondition(value, idx)
                                    }
                                    value={data.autoCondition}
                                  />
                                  <Input
                                    placeholder={t('variableName')}
                                    size={'middle'}
                                    style={{ width: 90, marginRight: 5 }}
                                    onChange={(e) => inputName(e, idx)}
                                    value={data.name}
                                  />
                                  <Select
                                    placeholder={t('operator')}
                                    size={'middle'}
                                    style={{ width: 70, marginRight: 5 }}
                                    options={oper}
                                    onChange={(value) =>
                                      selectOperator(value, idx)
                                    }
                                    value={data.operator}
                                  />
                                  <Input
                                    placeholder={t('value')}
                                    size={'middle'}
                                    style={{ width: 90, marginRight: 5 }}
                                    onChange={(e) => inputValue(e, idx)}
                                    value={data.value}
                                  />

                                  {idx > 0 && (
                                    <Button
                                      size={'small'}
                                      style={{
                                        height: 30,
                                        float: 'inline-end',
                                      }}
                                      onClick={() => conditionDel(idx)}
                                    >
                                      <FiMinus
                                        size={15}
                                        style={{ alignItems: 'center' }}
                                      />
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {conditionType === 'custom' && (
                    <div className={'mt-5'}>
                      <div className={'mt-5 w-[380px]'}>
                        <div className={'float-right'}>
                          <Button
                            size={'small'}
                            onClick={() => customKeyAdd()}
                            className={'h-[30px] float-end'}
                          >
                            <RiAddLine
                              size={15}
                              style={{ alignItems: 'center' }}
                            />
                          </Button>
                        </div>

                        <div>
                          {action === 'USER_SELECT' ? (
                            <>
                              {customKeyList.map((data: any, idx: number) => {
                                return (
                                  <div
                                    key={idx}
                                    style={{ marginTop: '10px' }}
                                    className={'w-[380px]'}
                                  >
                                    <Select
                                      placeholder={t('variableName')}
                                      size={'middle'}
                                      style={{ width: 340, marginRight: 5 }}
                                      options={variableList}
                                      onChange={(value) =>
                                        selectCustomKey(value, idx)
                                      }
                                      value={data}
                                    />

                                    {idx > 0 && (
                                      <Button
                                        size={'small'}
                                        style={{
                                          height: 30,
                                          float: 'inline-end',
                                        }}
                                        onClick={() => customKeyDel(idx)}
                                      >
                                        <FiMinus
                                          size={15}
                                          style={{ alignItems: 'center' }}
                                        />
                                      </Button>
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {customKeyList.map((data: any, idx: number) => {
                                return (
                                  <div
                                    key={idx}
                                    style={{ marginTop: '10px' }}
                                    className={'w-[380px]'}
                                  >
                                    <Input
                                      placeholder={t('enterVariable')}
                                      size={'middle'}
                                      className={'w-[340px]'}
                                      onChange={(e) => inputCustomKey(e, idx)}
                                      value={data}
                                    />

                                    {idx > 0 && (
                                      <Button
                                        size={'small'}
                                        style={{
                                          height: 30,
                                          float: 'inline-end',
                                        }}
                                        onClick={() => customKeyDel(idx)}
                                      >
                                        <FiMinus
                                          size={15}
                                          style={{ alignItems: 'center' }}
                                        />
                                      </Button>
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>

                      <div className={'mt-3'}>
                        <TextArea
                          rows={6}
                          value={inputChangeValue.customValue}
                          name="customValue"
                          onChange={onInputCustomValue}
                        ></TextArea>
                      </div>
                    </div>
                  )}
                </>
              )}

              {props.edgeData === null && action === 'CHECK' && (
                <div className={'border-b-[1px] mt-5'}></div>
              )}

              {((props.edgeData === null && action === 'CHECK') ||
                (props.edgeData === null &&
                  (action === 'CHECK_SELECT' || action === 'USER_SELECT'))) && (
                <>
                  <div
                    className={
                      props.edgeData === null &&
                      (action === 'CHECK_SELECT' || action === 'USER_SELECT')
                        ? 'mt-3 flex'
                        : 'mt-8 flex'
                    }
                  >
                    <span className={'mr-4 w-[30%] self-center'}>
                      액션 별칭
                    </span>
                    <Input
                      type="text"
                      placeholder={t('enterAlias')}
                      width={70}
                      name="alias"
                      value={commonValue.alias}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className={'mt-3 flex'}>
                    <span className={'mr-4 w-[30%] self-center'}>
                      {t('timeout')}
                    </span>
                    <Input
                      type="text"
                      placeholder={t('enterTimeout')}
                      className={'w-[330px] mr-3'}
                      name="timeout"
                      value={commonValue.timeout}
                      onChange={onInputChange}
                    />
                    <span className={'mr-4 self-center right-0'}>sec</span>
                  </div>
                  <div className={'mt-3 flex'}>
                    <span className={'mr-4 w-[30%] self-center'}>
                      {t('estimatedTime')}
                    </span>
                    <Input
                      type="text"
                      placeholder={t('enterEstimatedTime')}
                      className={'w-[330px] mr-3'}
                      name="estimatedTime"
                      value={commonValue.estimatedTime}
                      onChange={onInputChange}
                    />
                    <span className={'mr-4 self-center right-0'}>sec</span>
                  </div>
                </>
              )}
            </div>
          );
          break;
        }
        case 'CHANGE_MAP': {
          html = (
            <div>
              <div className={'mt-3 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>{t('map')}</span>
                <Input
                  type="text"
                  placeholder={t('enterMapId')}
                  name={'mapId'}
                  value={mapData.mapId}
                  onChange={onInputChange}
                />
                {/*<Select*/}
                {/*  placeholder={'지도를 선택하세요.'}*/}
                {/*  size={'middle'}*/}
                {/*  options={oper}*/}
                {/*  onChange={}*/}
                {/*  value={mapData.map}*/}
                {/*  className={'w-[75%]'}*/}
                {/*/>*/}
              </div>
              <div className={'mt-4 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  X{t('coordinate')}
                </span>
                <Input
                  type="text"
                  placeholder={`X ${t('enterCoordinate')}`}
                  name={'x'}
                  value={mapData.x}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-4 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  Y{t('coordinate')}
                </span>
                <Input
                  type="text"
                  placeholder={`Y ${t('enterCoordinate')}`}
                  name={'y'}
                  value={mapData.y}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-4 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  Z{t('coordinate')}
                </span>
                <Input
                  type="text"
                  placeholder={`Z ${t('enterCoordinate')}`}
                  name={'z'}
                  value={mapData.z}
                  onChange={onInputChange}
                />
              </div>

              <div className={'border-b-[1px] mt-5'}></div>

              <div className={'mt-8 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('actionAlias')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterAlias')}
                  width={70}
                  name="alias"
                  value={commonValue.alias}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-3 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('estimatedTime')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterEstimatedTime')}
                  className={'w-[250px] mr-3'}
                  name="estimatedTime"
                  value={commonValue.estimatedTime}
                  onChange={onInputChange}
                />
                <span className={'mr-4 self-center right-0'}>sec</span>
              </div>
            </div>
          );
          break;
        }

        case 'KEEP_OPEN':
        case 'OPEN_DOOR':
        case 'HALL_CALL':
        case 'CAR_CALL': {
          html = (
            <div>
              <div className={'mt-4 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>{t('carId')}</span>
                <Input
                  type="text"
                  name="groupId"
                  value={inputChangeValue.groupId}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-4 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('groupId')}
                </span>
                <Input
                  type="text"
                  name="carId"
                  value={inputChangeValue.carId}
                  onChange={onInputChange}
                />
              </div>

              <div className={'border-b-[1px] mt-5'}></div>

              <div className={'mt-8 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('actionAlias')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterAlias')}
                  width={70}
                  name="alias"
                  value={commonValue.alias}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-3 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('estimatedTime')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterEstimatedTime')}
                  className={'w-[250px] mr-3'}
                  name="estimatedTime"
                  value={commonValue.estimatedTime}
                  onChange={onInputChange}
                />
                <span className={'mr-4 self-center right-0'}>sec</span>
              </div>
            </div>
          );

          break;
        }

        // 액션별칭 하나만 있는 액션(노드)
        case 'CONTROL_DRAWER':
        case 'STOP':
        case 'APP_STOP':
        case 'ON_TIME':
        case 'WAIT':
        case 'AUTO_DOOR_OPEN':
        case 'AUTO_DOOR_CLOSE':
        case 'BACK_TO_SCHEDULE':
        case 'HOME':
        case 'IO_CONTROL':
        case 'CHARGE':
        case 'MOVE':
        case 'CHANGE_FLOOR':
        case 'CLEAN': {
          html = (
            <div>
              <div className={'mt-3 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('actionAlias')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterAlias')}
                  width={70}
                  name="alias"
                  value={commonValue.alias}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-3 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('estimatedTime')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterEstimatedTime')}
                  className={'w-[250px] mr-3'}
                  name="estimatedTime"
                  value={commonValue.estimatedTime}
                  onChange={onInputChange}
                />
                <span className={'mr-4 self-center right-0'}>sec</span>
              </div>
            </div>
          );

          break;
        }

        case 'APP_CONTROL': {
          html = (
            <div>
              <div className={'mr-4 w-[30%] self-center'}>
                {t('appControlCommand')}
              </div>
              <div className={'mt-3 flex'}>
                <TextArea
                  rows={6}
                  value={inputChangeValue.appControl}
                  name="appControl"
                  placeholder={'ex) { "key1": "value1", "key2": "value2" }'}
                  onChange={onInputCustomValue}
                ></TextArea>
              </div>

              <div className={'border-b-[1px] mt-5'}></div>
              <div className={'mt-8 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  s{t('actionAlias')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterAlias')}
                  width={70}
                  name="alias"
                  value={commonValue.alias}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-3 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('estimatedTime')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterEstimatedTime')}
                  className={'w-[250px] mr-3'}
                  name="estimatedTime"
                  value={commonValue.estimatedTime}
                  onChange={onInputChange}
                />
                <span className={'mr-4 self-center right-0'}>sec</span>
              </div>
            </div>
          );

          break;
        }
        case 'NOTICE_ALARM': {
          html = (
            <div>
              <div className={'mt-3 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('actionAlias')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterAlias')}
                  width={70}
                  name="alias"
                  value={commonValue.alias}
                  onChange={onInputChange}
                />
              </div>
              <div className={'mt-3 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>
                  {t('estimatedTime')}
                </span>
                <Input
                  type="text"
                  placeholder={t('enterEstimatedTime')}
                  className={'w-[250px] mr-3'}
                  name="estimatedTime"
                  value={commonValue.estimatedTime}
                  onChange={onInputChange}
                />
                <span className={'mr-4 self-center right-0'}>sec</span>
              </div>
              <div className={'border-b-[1px] mt-5'}></div>
              <div className={'mt-8 flex'}>
                <span className={'mr-4 w-[30%] self-center'}>알람 종류</span>
                <Select
                  placeholder={'알람 종류를 선택하세요'}
                  className={'w-[300px] mr-3'}
                  onChange={(value) => onSelectAlarmCategory(value)}
                  options={alarmCategoryList}
                  value={selectAlarmCategory}
                />
              </div>
              {selectAlarmCategoryData &&
              Object.keys(selectAlarmCategoryData).length > 0 ? (
                selectAlarmCategoryData.tplParams &&
                selectAlarmCategoryData.tplParams.length > 0 ? (
                  selectAlarmCategoryData.tplParams.map(
                    (item: any, i: number) => {
                      return (
                        <div key={i} className={'mt-3 flex'}>
                          <span className={'mr-4 w-[30%] self-center'}>
                            {i18n.resolvedLanguage === 'ko'
                              ? item.key.nameKr
                              : item.key.nameEn}
                          </span>
                          <Input
                            type="text"
                            width={70}
                            name={item.key.name}
                            value={item.value ? item.value : null}
                            onChange={(e) =>
                              onInputAlarmChange(e, item.key.name)
                            }
                          />
                        </div>
                      );
                    },
                  )
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
          );

          break;
        }
        default: {
          break;
        }
      }

      return html;
    }
  };

  const isNonEmpty = (obj: any) => {
    return obj.name && obj.value;
  };
  const isNonEmptySimple = (obj: any) => {
    return obj.name && obj.operator && obj.value;
  };

  const conditionValidation = (action: string) => {
    if (action === 'OUT_CONTROL') {
      const empty = outputConditionList.filter(isNonEmpty);

      if (empty.length === 0) {
        return 'fail';
      } else {
        return empty;
      }
    } else if (action === 'NOTICE_ALARM') {
      console.log('selectAlarmCategoryData = ', selectAlarmCategoryData);

      if (selectAlarmCategoryData.length > 0) {
        console.log('1');
        const tplParams = selectAlarmCategoryData.tplParams;
        if (tplParams && tplParams.length > 0) {
          console.log('2');
          const hasEmptyValue = tplParams.some(
            (item: any) => item.value === '',
          );

          console.log('hasEmptyValue', hasEmptyValue);

          if (hasEmptyValue) {
            return 'fail';
          } else {
            return '';
          }
        } else {
          return 'fail';
        }
      } else {
        return 'fail';
      }
    } else {
      if (conditionType === 'simple') {
        const empty = conditionList.filter(isNonEmptySimple);

        if (
          empty.length === 0 ||
          (action === 'CHECK'
            ? commonValue.timeout === ''
            : conditionName === '')
        ) {
          return 'fail';
        } else {
          return empty;
        }
      } else {
        if (customKeyList.length > 0) {
          const keyCheck = customKeyList.filter((item) => item !== '');

          const valueCheck = inputChangeValue.customValue;

          if (
            keyCheck.length === 0 ||
            valueCheck === '' ||
            (action === 'CHECK'
              ? commonValue.timeout === ''
              : conditionName === '')
          ) {
            return 'fail';
          } else {
            return keyCheck;
          }
        }
      }
    }
  };

  const save = (e: any) => {
    let data = {};

    if (action === 'CHECK') {
      const checkData = conditionValidation(action);

      if (checkData === 'fail') {
        toast.error(t('inputIsNotComplete'));
        return;
      } else {
        data = {
          conditionType: conditionType,
          conditionName: conditionName,
          conditionList: checkData,
          inputValue: inputChangeValue,
          commonValue: commonValue,
        };
      }
    } else if (action === 'CHECK_SELECT' || action === 'USER_SELECT') {
      let checkData = '';

      if (props.nodeData !== null && props.edgeData !== null) {
        checkData = conditionValidation(action);
      } else {
        commonValue.timeout === '' ? (checkData = 'fail') : '';
      }

      if (checkData === 'fail') {
        toast.error(t('inputIsNotComplete'));
        return;
      } else {
        data = {
          conditionType: conditionType,
          conditionName: conditionName,
          conditionList: checkData,
          inputValue: inputChangeValue,
          commonValue: commonValue,
        };
      }
    } else if (action === 'CHANGE_MAP') {
      data = {
        mapValue: mapData,
        commonValue: commonValue,
      };
    } else if (action === 'APP_CONTROL') {
      try {
        inputChangeValue.appControl = JSON.parse(inputChangeValue.appControl);

        data = {
          commonValue: commonValue,
          inputValue: inputChangeValue,
        };
      } catch (error) {
        toast.error(t('notJSONFormat'));
        return;
      }
    } else if (action === 'OUT_CONTROL') {
      const checkData = conditionValidation(action);

      if (checkData === 'fail') {
        toast.error(t('inputIsNotComplete'));
        return;
      } else {
        data = {
          commonValue: commonValue,
          conditionList: checkData,
        };
      }
    } else if (action === 'NOTICE_ALARM') {
      const checkData = conditionValidation(action);
      if (checkData === 'fail') {
        toast.error(t('inputIsNotComplete'));
        return;
      } else {
        data = {
          commonValue,
          alarmData: selectAlarmCategoryData,
        };
      }
    } else {
      data = {
        inputValue: inputChangeValue,
        commonValue: commonValue,
      };
    }

    props.callBackSidebarData(
      props.nodeData.id,
      (action !== 'CHECK_SELECT' && action !== 'USER_SELECT') ||
        props.edgeData === null
        ? null
        : props.edgeData,
      data,
      action,
    );

    console.log('data = ', data);

    props.onNodeDoubleClick(false);
  };

  useEffect(() => {
    const action = props.nodeData?.data.label.props.action;

    if (
      action === 'CHECK' ||
      action === 'CHECK_SELECT' ||
      action === 'USER_SELECT'
    ) {
      setDrawerWidth(450);
    } else {
      setDrawerWidth(378);
    }
  }, [props.nodeData]);

  return (
    <Drawer
      width={drawerWidth}
      onClose={onSettingSidebarClose}
      open={props.open}
      title={
        <div>
          <span>
            {title} {t('setting')}
          </span>
        </div>
      }
      style={{
        backgroundColor: 'white',
        borderLeft: '1px solid #eee',
        zIndex: '1000',
        position: 'relative',
      }}
      footer={
        <div style={{ display: 'flex' }}>
          <Button
            className={'buttonSwitch'}
            icon={<FaSave />}
            style={{ marginLeft: '5px', alignItems: 'center', display: 'flex' }}
            type={'primary'}
            onClick={(e) => save(e)}
          >
            {t('confirm')}
          </Button>
          <Button
            className={'buttonSwitch'}
            icon={<FaSave />}
            style={{ marginLeft: '5px', alignItems: 'center', display: 'flex' }}
            onClick={onSettingSidebarClose}
          >
            {t('cancel')}
          </Button>
        </div>
      }
    >
      <div>{settingDiv()}</div>
    </Drawer>
  );
};

export default settingSidebar;
