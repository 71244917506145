import { create } from 'zustand';

interface WorkflowData {
  robotInfo: any;
  robotCmdList: any;
  nodeList: any;
  edgeList: any;
  elvList: any;
  edgeType: string;
  robotType: string;
  siteNodeInfoList: any;
  variableParamList: any;

  changeRobotInfo: (inputRobotInfo: any) => void;
  changeRobotCmdList: (inputRobotCmdInfo: any) => void;
  changeNodeList: (inputNodeInfo: any) => void;
  changeEdgeList: (inputEdgeInfo: any) => void;
  setElvList: (inputEdgeInfo: any) => void;
  changeEdgeType: (selectEdgeType: string) => void;
  changeRobotType: (selectRobotType: string) => void;
  changeSiteNodeInfoList: (inputSiteNodeInfo: any) => void;
  changeVariableParamList: (paramList: object) => void;
}

const useWorkflowStore = create<WorkflowData>()((set) => ({
  robotInfo: null,
  robotCmdList: [],
  nodeList: [],
  edgeList: [],
  elvList: [],
  edgeType: 'default',
  robotType: 'single',
  siteNodeInfoList: [],
  variableParamList: {},

  changeRobotInfo: (inputRobotInfo) =>
    set((state) => ({
      robotInfo: inputRobotInfo,
    })),
  changeRobotCmdList: (inputRobotCmdInfo) =>
    set((state) => ({
      robotCmdList: inputRobotCmdInfo,
    })),
  changeNodeList: (inputNodeInfo) =>
    set((state) => ({
      nodeList: inputNodeInfo,
    })),
  changeEdgeList: (inputEdgeInfo) =>
    set((state) => ({
      edgeList: inputEdgeInfo,
    })),
  setElvList: (elvInfo) =>
    set((state) => ({
      elvList: elvInfo,
    })),
  changeEdgeType: (selectEdgeType) =>
    set((state) => ({
      edgeType: selectEdgeType,
    })),
  changeRobotType: (selectRobotType) =>
    set((state) => ({
      robotType: selectRobotType,
    })),
  changeSiteNodeInfoList: (inputSiteNodeInfo) =>
    set((state) => ({
      siteNodeInfoList: inputSiteNodeInfo,
    })),
  changeVariableParamList: (paramList) =>
    set((state) => ({
      variableParamList: paramList,
    })),
}));

export default useWorkflowStore;
