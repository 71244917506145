import { Button, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiAddLine, RiListSettingsLine } from 'react-icons/ri';
import React, { useEffect, useState } from 'react';
import { FiMinus } from 'react-icons/fi';
import { FaSave } from 'react-icons/fa';
import useWorkflowStore from '../../store/workflowStore';

interface PropsType {
  isModalOpen: boolean;
  setIsSettingVariableModalOpen: (value: boolean) => void;
  modalTitle: string;
  isReset: boolean;
  // setParamList: (value: object) => void;
}

export const SettingVariableModal = ({
  isModalOpen,
  setIsSettingVariableModalOpen,
  modalTitle,
  isReset,
}: PropsType) => {
  const { t } = useTranslation();

  const { variableParamList, changeVariableParamList } = useWorkflowStore();

  const [variableDeviceList, setVariableDeviceList] = useState<string[]>(['']);
  const [variableValueList, setVariableValueList] = useState<string[]>(['']);
  const [variableUserSelectList, setVariableUserSelectList] = useState<
    string[]
  >(['']);

  useEffect(() => {
    if (isReset) {
      setVariableDeviceList(['']);
      setVariableValueList(['']);
      setVariableUserSelectList(['']);
    }
  }, [isReset]);

  useEffect(() => {
    const { deviceId, actionParam, userParam } = variableParamList || {};

    if (deviceId?.length > 0) {
      setVariableDeviceList(deviceId);
    } else {
      setVariableDeviceList(['']);
    }

    if (actionParam?.length > 0) {
      setVariableValueList(actionParam);
    } else {
      setVariableValueList(['']);
    }

    if (userParam?.length > 0) {
      setVariableUserSelectList(userParam);
    } else {
      setVariableUserSelectList(['']);
    }
  }, [variableParamList]);

  const handleOk = () => {
    // 필터링 함수
    const filterEmptyStrings = (arr: any[]) =>
      arr.filter((item) => item !== '');

    const paramList = {
      deviceId: filterEmptyStrings(variableDeviceList),
      actionParam: filterEmptyStrings(variableValueList),
      userParam: filterEmptyStrings(variableUserSelectList),
    };

    // 변경된 파라미터 리스트를 설정
    changeVariableParamList(paramList);

    // 모달 닫기
    setIsSettingVariableModalOpen(false);
  };

  const handleCancel = () => {
    setIsSettingVariableModalOpen(false);
  };

  const handleAdd = (type: string) => {
    switch (type) {
      case 'device':
        setVariableDeviceList([...variableDeviceList, '']);
        break;
      case 'value':
        setVariableValueList([...variableValueList, '']);
        break;
      case 'userSelect':
        setVariableUserSelectList([...variableUserSelectList, '']);
        break;
    }
  };

  const handleDel = (type: string, idx: number) => {
    switch (type) {
      case 'device':
        variableDeviceList.splice(idx, 1);
        setVariableDeviceList([...variableDeviceList]);
        break;
      case 'value':
        variableValueList.splice(idx, 1);
        setVariableValueList([...variableValueList]);
        break;
      case 'userSelect':
        variableUserSelectList.splice(idx, 1);
        setVariableUserSelectList([...variableUserSelectList]);
        break;
    }
  };

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
    idx: number,
  ) => {
    const val = e.target.value;

    switch (type) {
      case 'device':
        const newDeviceList = variableDeviceList.map(
          (item: any, index: number) => {
            if (index === idx) {
              return val;
            }
            return item;
          },
        );
        setVariableDeviceList(newDeviceList);
        break;

      case 'value':
        const newValueList = variableValueList.map(
          (item: any, index: number) => {
            if (index === idx) {
              return val;
            }
            return item;
          },
        );
        setVariableValueList(newValueList);
        break;

      case 'userSelect':
        const newUserSelectList = variableUserSelectList.map(
          (item: any, index: number) => {
            if (index === idx) {
              return val;
            }
            return item;
          },
        );
        setVariableUserSelectList(newUserSelectList);
        break;
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      okText={t('confirm')}
      onCancel={handleCancel}
      cancelText={t('cancel')}
      width={430}
      footer={null}
    >
      <div className={'flex border-b-[1px] border-[#eee] pb-[15px]'}>
        <RiListSettingsLine size={25} />
        <span className={'text-[18px] ml-[10px] font-bold'}>{modalTitle}</span>
      </div>
      <div>
        {/* 가변 로봇 설정 */}
        <div className={'pt-5 pl-5 pr-5 pb-3 font-bold'}>
          {t('variableRobotSettings')}
        </div>
        {variableDeviceList.map((data: any, idx: number) => {
          return (
            <div key={idx} className={'flex'}>
              <Input
                size={'middle'}
                className={'ml-5 mb-2'}
                style={{ width: 300 }}
                onChange={(e) => handleInput(e, 'device', idx)}
                value={data}
              />

              {idx === 0 ? (
                <Button
                  size={'small'}
                  onClick={() => handleAdd('device')}
                  className={'h-[31px] float-end ml-2'}
                >
                  <RiAddLine size={15} style={{ alignItems: 'center' }} />
                </Button>
              ) : (
                <Button
                  size={'small'}
                  className={'h-[31px] float-end ml-2'}
                  onClick={() => handleDel('device', idx)}
                >
                  <FiMinus size={15} style={{ alignItems: 'center' }} />
                </Button>
              )}
            </div>
          );
        })}

        {/* 가변 변수 설정 */}
        <div className={'pt-5 pl-5 pr-5 pb-3 font-bold'}>
          {t('settingMutableVariables')}
        </div>
        {variableValueList.map((data: any, idx: number) => {
          return (
            <div key={idx} className={'flex'}>
              <Input
                size={'middle'}
                className={'ml-5 mb-2'}
                style={{ width: 300 }}
                onChange={(e) => handleInput(e, 'value', idx)}
                value={data}
              />

              {idx === 0 ? (
                <Button
                  size={'small'}
                  onClick={() => handleAdd('value')}
                  className={'h-[31px] float-end ml-2'}
                >
                  <RiAddLine size={15} style={{ alignItems: 'center' }} />
                </Button>
              ) : (
                <Button
                  size={'small'}
                  className={'h-[31px] float-end ml-2'}
                  onClick={() => handleDel('value', idx)}
                >
                  <FiMinus size={15} style={{ alignItems: 'center' }} />
                </Button>
              )}
            </div>
          );
        })}

        {/* 가변 변수 설정 */}
        <div className={'pt-5 pl-5 pr-5 pb-3 font-bold'}>
          {t('SettingUserBranchVariables')}
        </div>
        {variableUserSelectList.map((data: any, idx: number) => {
          return (
            <div key={idx} className={'flex'}>
              <Input
                size={'middle'}
                className={'ml-5 mb-2'}
                style={{ width: 300 }}
                onChange={(e) => handleInput(e, 'userSelect', idx)}
                value={data}
              />

              {idx === 0 ? (
                <Button
                  size={'small'}
                  onClick={() => handleAdd('userSelect')}
                  className={'h-[31px] float-end ml-2'}
                >
                  <RiAddLine size={15} style={{ alignItems: 'center' }} />
                </Button>
              ) : (
                <Button
                  size={'small'}
                  className={'h-[31px] float-end ml-2'}
                  onClick={() => handleDel('userSelect', idx)}
                >
                  <FiMinus size={15} style={{ alignItems: 'center' }} />
                </Button>
              )}
            </div>
          );
        })}
      </div>
      <div className={'flex justify-end mt-10'}>
        <Button className={'buttonSwitch'} type={'primary'} onClick={handleOk}>
          {t('confirm')}
        </Button>
        <Button className={'buttonSwitch'} onClick={handleCancel}>
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
};
