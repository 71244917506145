import { useEffect, useState } from 'react';
import { t } from 'i18next';
import Progress from '../schedule/Progress';
import Empty from '../empty';
import Spinner from '../loading/Spinner';
import useUserStore from '../../store/userStore';
import warningIcon from '../../assets/icon/icon_warning.svg';
import useTaskData from '../../hooks/useTaskData';

const Ongoing = () => {
  const { selectedUserAuthSite } = useUserStore();

  const [loading, setLoading] = useState<boolean>(true);

  const { taskSiteSocketData } = useTaskData({ type: 'sites' });

  useEffect(() => {
    if (selectedUserAuthSite.length === 0) return;

    setLoading(true);

    taskSiteSocketData.length > 0
      ? setLoading(false)
      : setTimeout(() => {
          setLoading(false);
        }, 800);
  }, [selectedUserAuthSite]);

  return (
    <>
      {!loading ? (
        taskSiteSocketData.length > 0 ? (
          taskSiteSocketData.map((item, idx) => (
            <Progress key={idx} loading={loading} taskData={item} />
          ))
        ) : (
          <div className="flex items-center justify-center w-full pt-[120px]  ">
            <Empty
              mainText={t('There_are_no_ongoing_works_at_the_moment')}
              subText={t('There_is_no_work_available_on_the_selected_site')}
            >
              {<img src={warningIcon} className="w-[48px] py-4" />}
            </Empty>
          </div>
        )
      ) : (
        <div className="mt-[250px]">
          <Spinner loading={loading} />
        </div>
      )}
    </>
  );
};

export default Ongoing;
